<template>
  <q-card v-if="course" class="text-left">
    <q-toolbar>
        <q-toolbar-title class="text-bold">{{ course.course_short_name }}<br> {{ course.course_name }}</q-toolbar-title>
        <q-input v-if="$q.screen.gt.sm && course.course_code && course.course_type !== 'template'" v-model="course.course_code" outlined disabled label="Course Code" class="q-ma-md">
          <template v-slot:append>
            <q-icon name="content_copy" @click="copy()"></q-icon>
          </template>
        </q-input>
        <q-btn icon="close" flat round dense @click="router.back()"/>
    </q-toolbar>
    <q-tabs
          v-model="tab"
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="justify"
        >
          <q-tab name="details" label="Details" />
          <q-tab v-if="course.uid && course.course_type!=='template'" name="users" label="Users" />
          <q-tab v-if="course.uid" name="activities" label="Activities" />
    </q-tabs>

    <q-separator />

    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="details">
        <q-form @submit.prevent.stop="save">
          <q-card-section align="right">
            <q-select :disable="!can_edit" v-if="course.course_type==='template'" label="Template Visibility" outlined style="min-width: 200px; max-width: 300px;" :options="visibility_options" v-model="visibility"></q-select>
          </q-card-section>
          <div class="row">
            <div class="col-md-6">
              <q-input :disable="!can_edit"
                       :rules="[val => !!val || 'Field is required']"
                       class="q-pa-md q-pb-lg" label="Course Short Name" v-model="course.course_short_name" outlined
                       hint="e.g. NUR 101">
              </q-input>
              <q-input :disable="!can_edit"
                       :rules="[val => !!val || 'Field is required']"
                       class="q-pa-md q-mb-lg" label="Course Name" v-model="course.course_name" outlined
                       hint="e.g. Introduction to Nursing">
              </q-input>
              <q-select :disable="!can_edit" v-if="programs.length && course.course_type!=='template'" class="q-pa-md" label="Program" option-label="program_name" v-model="course.program_embed" :options="programs" outlined></q-select>
              <q-select :disable="!can_edit" v-if="campuses.length && course.course_type!=='template'" class="q-pa-md" label="Campus" option-label="campus_name" v-model="course.campus_embed" :options="campuses" outlined>
                <template v-slot:option="scope">
                  <q-item v-bind="scope.itemProps">
                    <q-item-section>
                      <q-item-label>{{ scope.opt.campus_name }}</q-item-label>
                      <q-item-label caption>{{ scope.opt.city }}, {{ scope.opt.state }} {{ scope.opt.country }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
              <DateTimeField v-if="course.course_type!=='template'" class="q-ma-md" label="End" v-model="course.end_date"></DateTimeField>
              <div class="q-pa-md">
                <span class="q-field__label">Course Description</span>
                <q-editor :disable="!can_edit" v-model="course.course_description" min-height="5rem" />
              </div>
            </div>
            <div class="col-md-6">
              <q-btn :diable="!can_edit" v-if="course.uid && course.course_type !== 'template'" class="q-ma-md" color="primary" icon="add" @click="select_instructor">Add Instructors</q-btn>
              <div class="row q-col-gutter-md">
                <template v-for="user in instructors">
                  <CompactUserCard :user="user" @remove="remove_instructor(user.uid)" class="col-md-6"/>
                </template>
              </div>
            </div>
          <q-btn v-if="can_edit" class="q-ma-md" color="secondary" type="submit">Save</q-btn>
          <q-btn v-if="can_edit" class="q-ma-md" color="negative" @click="confirm_delete=true">Delete</q-btn>
          <q-btn v-if="can_edit && course.course_type !== 'template' && course.uid" class="q-ma-md" @click="make_template">Make Course into Template</q-btn>
          </div>
        </q-form>
      </q-tab-panel>
      <q-tab-panel name="users">
        <UsersView
            :course_uid="course.uid"
            :campus_uid="course.campus_embed ? course.campus_embed.uid : null"
            :program_uid="course.program_embed ? course.program_embed.uid : null"
            :organization_uid="course.organization_uid"
            :campus="course.campus_embed"
            :program="course.program_embed"
            @remove="user_removed"
            @add="user_added"
        ></UsersView>
      </q-tab-panel>
      <q-tab-panel name="activities">
        <Activities :course_uid="course.uid" :course="course"></Activities>
      </q-tab-panel>
    </q-tab-panels>
    <q-dialog v-model="confirm_delete">
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="delete" color="negative" text-color="white" />
          <span class="q-ml-sm">Are you sure you want to delete the Course: {{course.course_name }}?</span><br>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup />
          <q-btn label="Delete" color="negative" v-close-popup @click="regionStore.DeleteCourse(course); course.course_type==='template' ? $router.replace('/template_courses') : $router.replace('/courses')" />
        </q-card-actions>
      </q-card>
    </q-dialog>

  </q-card>
  <q-inner-loading
      :showing="loading"
      label="Please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />

</template>

<script>
import {ref, reactive, onMounted, nextTick, watch, computed} from 'vue'
import {copyToClipboard, Notify} from 'quasar'
import { useUserStore } from "../stores/user";
import { useRegionStore} from "../stores/region";
import { storeToRefs } from 'pinia'
import { useQuasar } from 'quasar'
import UserSelector from "../components/UserSelector.vue";
import UsersView from "./UsersView.vue";
import CompactUserCard from "../components/CompactUserCard.vue";
import Activities from "../components/Activities.vue";
import DateTimeField from "../components/DateTimeField.vue";
import { useRouter } from "vue-router";

export default {
  name: "Course",
  components: {DateTimeField, CompactUserCard, UsersView, Activities},
  props: ['is_new', 'organization_uid', 'campus_uid', 'program_uid', 'course_uid', 'template', 'added'],
  setup(props){
    const router = useRouter()
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    const regionStore = useRegionStore();
    const { org, course } = storeToRefs(regionStore)
    const organization_uid = ref(null);

    const tab = ref('activities');
    const loading = ref(false);
    const instructors = ref([]);
    const programs = ref([]);
    const campuses = ref([]);
    const confirm_delete = ref(false);
    const visibility = ref({label: 'Private', value: 'private'});
    onMounted(() => {
      organization_uid.value = props.organization_uid || org.value.uid;
      const c_promise = regionStore.GetCampuses();
      const p_promise = regionStore.GetPrograms();
      const p_array = [c_promise, p_promise];
      if(props.course_uid && (!course.value || course.value.uid !== props.course_uid)){
        course.value = null;
        p_array.push(regionStore.GetCourse(props.course_uid));
      }
      else{
        if(props.added){
          tab.value = 'details';
        }
        else if(course.value && course.value.course_type === 'template'){
          tab.value = 'activities';
        }
      }
      loading.value = true;
      Promise.all(p_array).then((values) => {
        campuses.value = values[0];
        programs.value = values[1];
        if(values.length > 2 && (!course.value || course.value.uid !== props.course_uid)) {
          course.value = values[2];
          if(props.added){
            tab.value = 'details';
          }
          else if (course.value.course_type === 'template') {
            tab.value = 'activities';
          }
          if (course.value.course_description === null) course.value.course_description = '';
        } else if(course.value && props.course_uid){
        } else {
          tab.value = 'details';
          course.value = {
            course_name: '',
            course_short_name: '',
            end_date: '',
            course_code: '',
            course_description: '',
            organization_uid: organization_uid.value,
            campus_uid: props.campus_uid,
            program_uid: props.program_uid,
            program_embed: null,
            campus_embed: null,
            course_type: props.template ? 'template' : 'instance',
            user_embed: null,
          }
          if(props.campus_uid) {
            for (let campus of campuses.value) {
              if (campus.uid == props.campus_uid) {
                course.value.campus_embed = campus;
                break;
              }
            }
          }
          else if(campuses.value.length == 1 && course.value.course_type !== 'template'){
            course.value.campus_embed = campuses.value[0];
          }
          if(props.program_uid) {
            for(let program of programs.value){
              if(program.uid == props.program_uid) {
                course.value.program_embed = program;
                break;
              }
            }
          }
          else if(programs.value.length == 1 && course.value.course_type !== 'template'){
            course.value.program_embed = programs.value[0];
          }
          else if(course.value.course_type === 'template'){
            course.value.user_embed = user.value;
          }
        }
        if (course.value.instructors) instructors.value = course.value.instructors;
        
        if(course.value.program_embed && course.value.user_embed){
          visibility.value = {label: `Visible to ${course.value.program_embed.name}`, value: 'public', program: course.value.program_embed};
        }
        else if(course.value.organization_embed && course.value.user_embed) {
          visibility.value = {
            label: `Visible to ${course.value.organization_embed.short_name ? course.value.organization_embed.short_name : course.value.organization_embed.name}`,
            value: 'public',
            organization: course.value.organization_embed
          };
        }
        else if(course.value.user_embed){
          visibility.value = {label: 'Private', value: 'private'};
        }
        else if(course.value.organization_embed){
          visibility.value = {label: `${course.value.organization_embed.value.short_name ? course.value.organization_embed.value.short_name : course.value.organization_embed.value.name} course.value`, value: 'organization'}
        }
        else if(course.value.program_embed){
          visibility.value = {label: `${course.value.program_embed.program_name} course`, value: 'program', program: course.value.program_embed};
        }
        else if(course.value.organization_draft_embed){
          visibility.value = {label: `Draft ${course.value.organization_draft_embed.value.short_name ? course.value.organization_embed.value.short_name : course.value.organization_embed.value.name} course.value`, value: 'draft organization'}
        }
        else if(course.value.program_draft_embed){
          visibility.value = {label: `Draft ${course.value.program_draft_embed.program_name} course`, value: 'draft program', program: course.value.program_embed};
        }
        else if(course.value.system_course === true){
          visibility.value = {label: 'System Course', value: 'system'};
        }
        else if(course.value.system_draft_course === true){
          visibility.value = {label: 'Draft System Course', value: 'draft system'};
        }        
        
        loading.value = false;
      });
    });

    const $q = useQuasar()

    function select_instructor() {
      $q.dialog({
        component: UserSelector,

        // props forwarded to your custom component
        componentProps: {
          organization_uid: org.value.uid,
          role: "Instructor",
          campus: course.value.campus_embed ? course.value.campus_embed : null,
          program: course.value.program_embed ? course.value.program_embed : null,
          // ...more..props...
        }
      }).onOk((new_users) => {
        if(new_users.length > 0) {
          loading.value = true
          console.log('OK');
          console.log(new_users);
          regionStore.AddOrInviteUsers(new_users, "Instructor", {
            entity_type: 'course',
            uid: course.value.uid
          }).then((user) => {
            for (let nu of user) {
              let found = false;
              for (let u in instructors.value) {
                if (instructors.value[u].uid == nu.uid) {
                  instructors.value[u] = nu
                  found = true
                  break
                }
              }
              if (!found) {
                instructors.value.push(nu);
              }
            }
            loading.value = false
          })
        }
      }).onCancel(() => {
        console.log('Cancel')
      }).onDismiss(() => {
        console.log('Called on OK or Cancel')
      })
    }

    const visibility_options = computed(()=> {
      let options = [{label: 'Private', value: 'private'}];
      // Add the current organization
      if(org.value){
        if(userStore.check_roles(['Organization Admin', 'Organization Content Admin'], org.uid)){
          options.push({label: `${org.value.short_name ? org.value.short_name : org.value.name} Patient`, value: 'organization'});
          options.push({label: `Draft ${org.value.short_name ? org.value.short_name : org.value.name} Patient`, value: 'draft organization'});
        }
        // Add the available programs
        const programs = userStore.programs_for_organization(org.uid);
        if(programs.length > 0) {
          for(let program of programs) {
            options.push({label: `Visible to ${program.name}`, value: 'public', program: program});
            if (userStore.check_roles(['Program Admin', 'Program Content Admin'], null, program.uid)) {
              options.push({label: `${program.name} Patient`, value: 'program', program: program});
              options.push({label: `Draft ${program.name} Patient`, value: 'draft program', program: program});
            }
          }
        }
        else{
          options.push({label: `Visible to ${org.value.short_name ? org.value.short_name : org.value.name}`, value: 'public', organization: org.value});
        }
      }
      //Add System Patient
      if(userStore.check_roles(['Superuser', 'Content Manager'])){
        options.push({label: 'Draft System Course', value: 'draft system'});
        options.push({label: 'System Course', value: 'system'});
      }
      return options;
    });

    const can_edit= computed(() => {
      if(course.value.course_type !== 'template') return true;

      if(course.value.user_embed && course.value.user_embed.uid === user.value.uid){
        return true
      }
      if(course.value.organization_embed && userStore.check_roles(['Organization Admin', 'Organization Content Admin'], course.value.organization_embed.uid)){
        return true
      }
      if(course.value.program_embed && userStore.check_roles(['Program Admin', 'Program Content Admin'], org.value.uid, course.value.program_embed.uid)){
        return true
      }
      if(course.value.organization_draft_embed && userStore.check_roles(['Organization Admin', 'Organization Content Admin'], course.value.organization_draft_embed.uid)){
        return true
      }
      if(course.value.program_draft_embed && userStore.check_roles(['Program Admin', 'Program Content Admin'], org.value.uid, course.value.program_draft_embed.uid)){
        return true
      }
      if((course.value.system_course===true || course.value.system_draft_course===true) && userStore.check_roles(['Superuser', 'Content Manager']) ) return true;
      return false;
    });
    
    return {
      can_edit,
      confirm_delete,
      visibility,
      visibility_options,
      regionStore,
      org,
      router,
      instructors,
      tab,
      course: course,
      loading: loading,
      programs,
      campuses,
      save(){
        loading.value = true;
        
      if(course.value.course_type==='template'){
        //Set the Visibility Options
        course.value.user_embed = null;
        course.value.organization_embed = null;
        course.value.program_embed = null;
        course.value.organization_draft_embed = null;
        course.value.program_draft_embed = null;
        course.value.system_course = null;
        course.value.system_draft_course = null;
        if(visibility.value.value === 'private'){
          course.value.user_embed = user.value;
        }
        else if(visibility.value.value === 'organization'){
          course.value.organization_embed = org.value;
        }
        else if(visibility.value.value === 'system'){
          course.value.system_course = true;
        }
        else if(visibility.value.value === 'draft system'){
          course.value.system_draft_course = true;
        }
        else if(visibility.value.value === 'draft organization'){
          course.value.organization_draft_embed = org.value;
        }
        else if(visibility.value.value === 'public'){
          course.value.user_embed = user.value;
          if(visibility.value.organization){
            course.value.organization_embed = visibility.value.organization;
          }
        }
      }
        
        
        regionStore.UpdateCourse(course.value).then((data) => {
          if(data.user){
            user.value = data.user;
          }
          course.value = data.course;
          if(course.value.instructors) instructors.value = course.value.instructors;
          loading.value = false;
          if(props.is_new){
            router.replace(`/course/${course.value.uid}`)
            if(userStore.check_roles(
                ['Instructor'],
                course.value.organization_uid,
                course.value.program_embed ? course.value.program_embed.uid : null,
                course.value.campus_embed ? course.value.campus_embed.uid : null,
                course.value.uid)){
            }
            tab.value = 'activities';
          }
        })
      },
      remove_instructor(uid){
        loading.value = true;
        regionStore.RemoveUser(uid, {entity_type: 'course', uid: course.value.uid}).then((success) => {
          if(success){
            instructors.value = instructors.value.filter(user => user.uid !== uid);
          }
          loading.value = false;
        });
      },
      copy(){
        copyToClipboard(course.value.course_code);
        $q.notify({
          color: 'positive',
          message: `Copied Course Code to Clipboard`,
          icon: 'save'
        })
      },
      select_instructor,
      user_removed(user_uid){
        instructors.value = instructors.value.filter(user => user.uid !== user_uid);
      },
      user_added(data){
        if(data.role !== 'Instructor') return;
        let found = false;
        for (let u in instructors.value) {
          if (instructors.value[u].uid == data.user.uid) {
            instructors.value[u] = data.user
            found = true
            break
          }
        }
        if (!found) {
          instructors.value.push(data.user);
        }
      },
      make_template(){
        loading.value = true;
        regionStore.MakeTemplateCourse(course.value).then((data) => {
          course.value = data;
          loading.value = false;
          router.push(`/course/${course.value.uid}`)
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
