<template>
  <q-card v-if="version">
    <q-input class="q-pa-md" outlined label="Version Name" v-model="version.version_name"></q-input>
    <span class="q-ml-md">Comments:</span>
    <q-editor class="q-ma-md keepborder" v-model="version.comment" label="Comment"></q-editor>
    <q-btn class="q-ma-sm" color="primary" @click="save()">Save</q-btn>
    <q-btn class="q-ma-sm" color="secondary" @click="publish">Publish</q-btn>
    <q-btn class="q-ma-sm" color="negative" @click="confirm_delete=true">Delete</q-btn>
    <q-table title="Versioned Items" :columns="columns" :rows="version.items">
      <template v-slot:body-cell-item_name="props">
        <q-td>
          <span v-if="props.row.entity_type==='flowsheet'">{{ props.row.flowsheet_name }}</span>
          <span v-else-if="props.row.entity_type==='chart'">{{ props.row.chart_name }}</span>
          <span v-else>{{ props.row.field_name }}</span>
        </q-td>
      </template>
      <template v-slot:body-cell-actions="props">
        <q-td>
          <q-btn v-if="props.row.entity_type==='flowsheet'" class="q-ma-sm" rounded color="primary" @click="edit_flowsheet(props.row)" size="sm">Edit Flowsheet</q-btn>
          <q-btn v-else-if="props.row.entity_type==='chart'" class="q-ma-sm" rounded color="primary" @click="edit_chart(props.row)" size="sm">Edit Chart</q-btn>
          <q-btn v-else-if="props.row.entity_type==='lookup_type'" class="q-ma-sm" rounded color="primary" @click="edit_lookup(props.row)" size="sm">Edit Lookup</q-btn>
        </q-td>
      </template>
    </q-table>
    <q-dialog v-model="confirm_delete">
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="delete" color="negative" text-color="white" />
          <span class="q-ml-sm">Are you sure you want to delete the version: {{version.name }}?</span><br>
          <span>This will delete all Unpublished changes to Flowsheets, Fields, Lookups and Visit Types made in this Version</span>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup />
          <q-btn label="Delete" color="negative" v-close-popup @click="flowsheetStore.DeleteVersion(version); $router.replace(`/versions`)" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-card>
  <q-inner-loading
    :showing="loading"
    label="Please wait..."
    label-class="text-secondary"
    label-style="font-size: 1.1em"
  />
</template>

<script>
import { useFlowsheetStore } from "../stores/flowsheet";
import {useRouter} from "vue-router";
import {onMounted, ref} from "vue";

export default {
  name: "Version",
  props: ["version_uid"],
  setup(props) {
    const flowsheetStore = useFlowsheetStore();
    const loading = ref(false);
    const version = ref(null);
    const confirm_delete = ref(false);

    onMounted(() => {
      if(props.version_uid === "new"){
        version.value = {
          version_name: '',
          comment: '',
        }
      }
      else {
        loading.value = true;
        flowsheetStore.GetVersion(props.version_uid).then(f => {
          version.value = f.version;
          loading.value = false;
        });
      }
    });

    return {
      columns: [{
        name: 'item_type',
        label: 'Item Type',
        field: 'entity_type',
        align: 'left',
        sortable: true,
      }, {
        name: 'item_name',
        label: 'Item Name',
        align: 'left',
        sortable: true,
      },
      {
          name: 'actions',
          label: 'Actions',
          field: 'uid',
          align: 'left',
          sortable: false,
        },
      ],
      flowsheetStore,
      loading,
      version,
      confirm_delete,
      save(){
        loading.value = true;
        flowsheetStore.SaveVersion(version.value).then(()=>{
          loading.value = false;
        });
      }
    }
  },
  methods: {
    edit_flowsheet(flowsheet) {
      this.$router.push(`/flowsheet_builder/${flowsheet.version}/${flowsheet.uid}`);
    },
    edit_chart(chart){
      this.$router.push(`/chart/${chart.version}/${chart.uid}`);
    },
    edit_lookup(lookup){
      this.$router.push(`/lookups/${lookup.flowsheets[0].uid}/${lookup.uid}?version=${lookup.version}`);
    },
    publish(){
      this.loading = true;
      this.flowsheetStore.PublishVersion(this.version.uid).then(()=>{
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>
.keepborder {
  border: 1px solid #e0e0e0;
  border-radius: 6px !important;
}
</style>
