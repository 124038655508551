<template>
    <!--<q-fab class="absolute" style="right: 10px;" v-if="!editing && (new_mode || edit_mode)" color="primary" flat icon="close" @click="new_mode=false; edit_mode=null;"></q-fab>-->
    <div class="row q-pa-md">
      <div class="col-6 text-h5">
        {{ flowsheet.flowsheet_name }}
      </div>
    </div>

    <div v-if="column">
      <q-card v-if="show_timestamp===true || show_timestamp===undefined" class="q-ma-md row bg-primary">
        <div class="col-shrink">
          <q-avatar class="q-ma-md" size="36px" color="secondary">
            <img v-if="column.user_embed.picture" :src="column.user_embed.picture"/>
            <span v-else>{{ column.user_embed.initials }}</span>
            <q-tooltip class="bg-secondary text-white">
              {{ column.user_embed.username }}
            </q-tooltip>
          </q-avatar>
        </div>
        <div class="col">
          <div class="q-ma-md">
            <PatientDateTimeField dense bg-color="grey-4" :patient="patientStore.patient" v-model="column.timestamp" :disable="readonly"></PatientDateTimeField>
          </div>
        </div>
      </q-card>
      <draggable v-model="flowsheet.fields" item-key="uid" group="fields" :disabled="!editing" tag="div">
        <template #item="{element}">
          <q-card
              v-if="element.data_type === 'expansion'"
              class="bg-accent text-white text-bold q-ma-md"
              :class="{ 'active-row': active_field ? element.uid === active_field.uid : false}"
              @click="$emit('selected', element); element.visible = !element.visible; update_element_visibility(element)">
            <q-card-section>
              {{ element.field_name }} <q-icon :name="element.visible ? 'expand_less' : 'expand_more'"></q-icon>
            </q-card-section>
          </q-card>
          <q-card v-else :class="{ 'active-row': active_field ? element.uid === active_field.uid : false, 'q-ma-md': true, 'hidden': element.visible === false ? true : false}" @click="$emit('selected', element)">
            <q-card-section class="bg-primary text-white">
              {{ element.field_name }}
              <template v-if="['Advanced', 'Lookup Advanced'].includes(element.data_type) && !readonly">
                <q-btn v-if="new_mode || edit_mode" icon="add" round size="sm" color="accent" @click="start_advanced_add(element)"></q-btn>
              </template>
            </q-card-section>
            <template v-if="['Advanced', 'Lookup Advanced'].includes(element.data_type)">
              <template v-for="entry in get_entries(element)">
                <template v-for="(obj, field_id) in data[entry]">
                  <q-card-section >
                    <div class="row">
                      <div class="col">
                        <template v-if="element.data_type==='Lookup Advanced'">
                          <TipTap v-model="flowsheet.lookup_data[obj.lookup.lookup_type].advanced_render" :edit="false" :lookup_type="flowsheet.lookup_data[obj.lookup.lookup_type]" :data="obj"></TipTap>
                        </template>
                        <template v-else>
                          <TipTap v-if="element.advanced_render" v-model="element.advanced_render" :lookup_type="element" :edit="false" :data="obj"></TipTap>
                          <template v-else v-for="field in get_fields(element, obj)">
                            <span class="text-bold">{{field.field_name}}:&nbsp;</span>
                            <component :edit="false" outlined dense :is="lookup(field.data_type)" v-model="obj[field.uid]" :patient="patient" :field="field" :readonly="readonly"></component>
                            <br>
                          </template>
                        </template>
                      </div>
                      <div class="col col-shrink">
                        <q-btn v-if="(new_mode || edit_mode) && can_edit(obj.user_embed.uid) && !readonly" color="grey-4" round size="sm" class="q-ma-sm" @click="open_advanced(element.data_type==='Lookup Advanced' ? flowsheet.lookup_data[obj.lookup.lookup_type] : element, obj, field_id)">
                          <q-icon class="text-primary" name="edit"></q-icon>
                        </q-btn>
                        <q-btn v-if="['template', 'scene'].includes(patient.patient_type) && (new_mode || edit_mode) && can_edit(obj.user_embed.uid)" color="negative" round size="sm" class="q-ma-sm" @click="delete_advanced(element.data_type==='Lookup Advanced' ? flowsheet.lookup_data[obj.lookup.lookup_type] : element, field_id, obj)">
                          <q-icon class="text-white" name="close"></q-icon>
                        </q-btn>
                      </div>
                    </div>
                    <div>
                      <template
                        v-if="obj[entry] && obj[entry][field_id]"
                        v-for="(action_data, action_uid) in obj[entry][field_id]"
                      >
                        <q-chip
                            v-if="get_action(element, obj, action_uid)"
                            clickable
                            removable
                            @remove="delete_action(obj, element, field_id, action_uid, obj)"
                            @click="select_action(obj, element, field_id, get_action(element, obj, action_uid), obj, false)"
                        >
                          <!--<TipTap v-if="get_action(element, obj, action_uid).advanced_render" v-model="get_action(element, obj, action_uid).advanced_render" :lookup_type="get_action(element, obj, action_uid)" :edit="false" :data="newcolumn[element.uid][field_id][action_uid]"></TipTap>-->
                          <q-icon v-if="get_action(element, obj, action_uid).fields.length > 0" name="comment" class="q-pr-sm"></q-icon>
                          <span>{{get_action(element, obj, action_uid).action_name}}</span>
                        </q-chip>
                      </template>
                      <q-btn-dropdown v-else-if="!readonly" label="Action" color="accent">
                        <q-list dense>
                          <q-item clickable v-close-popup v-for="action in get_actions(element, obj)" @click="select_action(obj, element, field_id, action, obj)">
                            <q-item-section>
                              <q-item-label>
                                {{action.action_name}}
                              </q-item-label>
                            </q-item-section>
                          </q-item>
                        </q-list>
                      </q-btn-dropdown>
                    </div>
                  </q-card-section>
                  <q-separator></q-separator>
                </template>
              </template>
            </template>
            <q-card-section v-else>
              <component :edit="true" outlined dense :is="lookup(element.data_type)" v-model="column[element.uid]" :patient="patientStore.patient" :field="element" :readonly="readonly"></component>
            </q-card-section>
            <PatientHelp :element="element"></PatientHelp>
          </q-card>
        </template>
      </draggable>
    </div>
    <q-page-sticky v-if="dialog===undefined" class="q-ma-md">
      <q-fab v-if="!editing && (new_mode || edit_mode!==null)" color="secondary" label="Save" icon="save" @click="save"></q-fab>
    </q-page-sticky>

</template>

<script>
import draggable from 'vuedraggable'
import NumericField from "./NumericField.vue";
import PatientDateTimeField from "../PatientDateTimeField.vue";
import {nextTick, onMounted, provide, ref, toRef, watchEffect, watch} from "vue";
import { epoch_offset_to_datetime, date_time_string, datetime_to_epoch_offset, date_string, time_string} from "../../util";
import {usePatientData} from "../../composables/patient_data";
import AdvancedForm from "./AdvancedForm.vue";
import PatientHelp from "./PatientHelp.vue";
import TipTap from "../TipTap.vue";

export default {
  name: "Form",
  props: ["flowsheet", "editing", "active_field", "data", "new_mode", "dialog", "timestamp", 'show_timestamp', 'readonly'],
  emits: ['update:timestamp'],
  components: {
    PatientHelp,
    PatientDateTimeField,
    draggable,
    NumericField,
    AdvancedForm,
    TipTap
  },
  setup(props, {emit}) {
    const data = toRef(props, 'data');
    const {
        patient,
        newcolumn,
        advanced_obj,
        advanced_element,
        new_ele,
        new_mode,
        edit_mode,
        readonly,
        patientStore,
        userStore,
        viewing_visit,
        start_advanced_add,
        open_advanced,
        delete_advanced,
        new_entry,
        save,
        edit,
        lookup,
        get_entries,
        get_fields,
        can_edit,
        reset_data,
        get_actions,
        select_action,
        get_action,
        delete_action,
    } = usePatientData({...props, data: data.value});

    provide('flowsheet', toRef(props, 'flowsheet'));
    provide('patient', patient);

    const column = ref(null);
    onMounted(() => {
      if (props.data.times.length > 0){
        if (props.timestamp !== undefined && props.timestamp !== null){
          column.value = props.data.times.find(data => data.timestamp === props.timestamp);
          if(!column.value){
            new_entry();
            column.value = newcolumn.value;
            column.value.timestamp = props.timestamp;
          }
        }
        else {
          column.value = props.data.times[props.data.times.length-1];
        }
        edit(column.value)
      }
      else {
        new_entry();
        column.value = newcolumn.value;
      }
    });

    watch(() => props.timestamp, (newVal, oldVal) => {
      if (newVal !== oldVal){
        if (props.data.times.length > 0){
          column.value = props.data.times.find(data => data.timestamp === props.timestamp);
          if(!column.value){
            new_entry();
            column.value = newcolumn.value;
            column.value.timestamp = props.timestamp;
          }
        }
        else {
          new_entry();
          column.value = newcolumn.value;
        }
        edit(column.value)
      }
    });

    watch(() => column.value.timestamp, (newVal, oldVal) => {
      if (newVal !== oldVal){
        emit('update:timestamp', newVal);
      }
    });

    watchEffect(() => {
      for(let fieldIndex in props.flowsheet.fields) {
        let field = props.flowsheet.fields[fieldIndex];
        if(field.data_type === "expansion") {
          field.visible = false;
          nextTick(() => {
            update_element_visibility(field);
          })
        }
      }
    });

    const update_element_visibility = function(element){
      let marking = false;
      for(let i=0; i<props.flowsheet.fields.length; i++){
        if(props.flowsheet.fields[i].uid === element.uid){
          marking = true;
          continue;
        }
        if(marking===true){
          if(props.flowsheet.fields[i].data_type === 'expansion'){
            marking = false;
            break;
          }
          props.flowsheet.fields[i].visible = element.visible;
        }
      }

    }

    return {
      patient,
      viewing_visit,
      date_string,
      time_string,
      new_mode,
      edit_mode,
      readonly,
      patientStore,
      userStore,
      column,
      start_advanced_add,
      open_advanced,
      delete_advanced,
      select_action,
      get_action,
      delete_action,
      get_actions,
      lookup,
      new_ele,
      advanced_obj,
      advanced_element,
      save,
      get_entries,
      get_fields,
      can_edit,
      reset_data,
      update_element_visibility,
    }
  }
}
</script>

<style lang="scss">
  /*.flowsheet-table tbody tr:nth-child(even) td:first-child{
    background-color: #ffffff;
  }*/

  .active-row {
    background-color: $positive !important;
  }

  .flowsheet-table tr td:first-child{
    position: sticky;
    z-index: 1;
    left: 0;
  }

  .flowsheet-table tr th:first-child{
    position: sticky;
    z-index: 2;
    left: 0;
  }

</style>
