<template>
  <q-card v-if="user" class="text-center">
    <q-card-section>
        <q-form @submit.prevent="save">
          <q-btn class="absolute-top-right q-ma-sm" icon="close" flat round dense @click="router.back()"/>
          <ProfilePicUploader v-model="user.picture" />
          <q-input class="q-pa-md" label="First Name" v-model="user.first_name" outlined></q-input>
          <q-input class="q-pa-md" label="Last Name" v-model="user.last_name" outlined></q-input>
          <q-input class="q-pa-md" label="User Name" v-model="user.username" outlined></q-input>
          <q-input class="q-pa-md" label="Initials" v-model="user.initials" outlined></q-input>
          <q-btn v-if="userStore.user && userStore.check_roles(['Superuser', 'Support'])" class="q-ma-md" color="negative" @click="confirm_delete=true">Delete</q-btn>
          <q-btn class="q-ma-md" color="secondary" type="submit">Save</q-btn>
          <q-btn class="q-ma-md" color="primary" @click="set_password=true">Change Password</q-btn>
        </q-form>
    </q-card-section>
    <q-card-section>
      <h4>Contact and Login Information</h4>
      <q-btn class="q-ma-sm" color="primary" @click="new_email=true">New Email</q-btn>
      <q-table v-if="user && user.uams"
        :rows="filtered_uams"
        :columns="columns"
        row-key="system_key"
        separator="vertical"
      >
        <template v-slot:body-cell-actions="props">
          <q-btn class="q-ma-sm" v-if="user.uams.length>1" rounded color="negative" @click="delete_email(props.row)" size="sm">Delete</q-btn>
        </template>
      </q-table>
    </q-card-section>
    <!--<q-card-section>
      <h4>Schools</h4>
      <q-tree :nodes="school_tree" default-expand-all node-key="key" class="text-left" no-nodes-label="This user does not belong to any schools">
        <template v-slot:default-body="prop">
          <span>{{ prop.node.body }}</span>
        </template>
      </q-tree>
    </q-card-section>-->
  </q-card>
  <q-inner-loading
      :showing="loading"
      label="Please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />
  <q-dialog v-model="confirm_delete" persistent>
    <q-card>
      <q-card-section>
        Are you sure you want to delete the user: {{ user.first_name }} {{ user.last_name }}?
      </q-card-section>
      <q-card-actions>
        <q-btn class="q-ma-sm" @click="confirm_delete=false">Cancel</q-btn>
        <q-btn class="q-ma-sm" color="negative" @click="delete_user">Delete</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="set_password">
    <NewPassword emit @close="set_password=false" :user_uid="user.uid"></NewPassword>
  </q-dialog>
  <q-dialog v-model="new_email">
    <q-card>
      <q-card-section>
        <q-input v-model="email_address" label="New Email" outlined></q-input>
      </q-card-section>
      <q-card-actions>
        <q-btn class="q-ma-sm" @click="new_email=false">Cancel</q-btn>
        <q-btn class="q-ma-sm" color="primary" @click="add_email">Add</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>

</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue'
import { useUserStore } from "../stores/user";
import { useRouter} from "vue-router";
import { storeToRefs } from 'pinia'
import ProfilePicUploader from "../components/ProfilePicUploader.vue";
import NewPassword from "./NewPassword.vue";

export default {
  name: "Profile",
  components: {NewPassword, ProfilePicUploader},
  props: ["uid"],
  setup(props, { attrs, slots, emit, expose }){
    const router = useRouter();
    const userStore = useUserStore();
    const user = ref({});
    const loading = ref(false);
    const set_password = ref(false);
    const new_email = ref(false);
    const email_address = ref('');

    onMounted(() => {
      loading.value = true;
      userStore.getUser(props.uid || userStore.user.uid).then(data => {
        user.value = data;
        loading.value = false;
      });
    });

    const filtered_uams = computed(() => {
      return user.value.uams.filter(uam => uam.system !== 'stripe');
    });

    return {
      columns: [
        {
          name: 'system',
          label: 'Login or Contact Type',
          field: 'system',
          align: 'left',
          sortable: true,
        },
        {
          name: 'system_key',
          label: 'Login or Contact Info',
          field: 'system_key',
          align: 'left',
          sortable: true,
        },
        {
          name: 'actions',
          label: 'Actions',
          align: 'left',
          sortable: false,
        },
      ],
      filtered_uams,
      router,
      loading,
      user: user,
      userStore,
      set_password,
      confirm_delete: ref(false),
      new_email,
      email_address,

    }
  },
  computed: {
    school_tree(){
      const tree = [];
      if(this.user && this.user.schools){
        for (let school_key in this.user.schools) {
          let school = this.user.schools[school_key];
          const node = {
            key: school_key,
            label: school_key,
            body: school.role,
            children: [],
          };
          for (let campus in school.campuses) {
            node.children.push({
              key: campus,
              label: campus,
              body: school.campuses[campus]['role'],
              children: [],
            });
          }
          for (let program in school.programs) {
            node.children.push({
              key: program,
              label: program,
              body: school.programs[program]['role'],
              children: [],
            });
          }
          tree.push(node);
        }
      }
      return tree;
    }
  },
  methods: {
    save(){
      this.loading = true
      this.userStore.UpdateUser(this.user).then(() => {
        this.loading = false
      })
    },
    delete_user(){
      this.loading = true
      this.userStore.DeleteUser(this.user).then(() => {
        this.loading = false
        this.confirm_delete = false
        this.$router.back();
      });
    },
    add_email(){
      this.loading = true
      this.userStore.AddEmail(this.user, this.email_address).then((r) => {
        this.user = r.user
        this.loading = false
        this.new_email = false
        if(this.user.uid === this.userStore.user.uid) {
          this.$router.push(`/verification?email=${this.email_address}&redirect_to=/profile`)
        }
      });
    },
    delete_email(row){
      this.loading = true
      this.userStore.DeleteEmail(this.user, row.system_key).then((r) => {
        this.user = r.user
        this.loading = false
      });
    }
  },
  watch: {
    "user.first_name": function(val){
      if(this.user && !this.user.initials){
        const initial1 = val ? val.charAt(0).toUpperCase() : ''
        const initial2 = this.user.last_name ? this.user.last_name.charAt(0).toUpperCase() : ''
        this.user.initials = initial1 + initial2
        if(!this.user.username && val || this.user.username === this.user.first_name + ' ' + this.user.last_name){
          this.user.username = val + ' ' + (this.user.last_name ? this.user.last_name : '')
        }
      }
    },
    "user.last_name": function(val){
      if(this.user && !this.user.initials){
        const initial1 = this.user.first_name ? this.user.first_name.charAt(0).toUpperCase() : ''
        const initial2 = val ? val.charAt(0).toUpperCase() : ''
        this.user.initials = initial1 + initial2
        if(!this.user.username && val || this.user.username === this.user.first_name + ' ' + this.user.last_name) {
          this.user.username = (this.user.first_name ? this.user.first_name : '') + ' ' + val
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
