<script setup>
  const props = defineProps(['is_new', 'organization_uid'])

  import {onMounted, ref} from "vue";
  import {useRegionStore} from "@/stores/region";
  import {storeToRefs} from "pinia";

  const regionStore = useRegionStore();
  const { org } = storeToRefs(regionStore);
  const purchase = ref(null);
  const product = ref(null);
  const options = ref(null);
  const loading = ref(false);

  function handle_product_list(){
    regionStore.GetProducts().then((products) => {
      options.value = products.filter((p) => (p.default_price && org.value.bookstore_organization.policy.student_pay_prices.includes(p.default_price.id)));
    });
  }

  onMounted(() => {
    purchase.value = {
      purchaser_entity: 'organization',
      purchaser_uid: props.organization_uid,
      qty: null,
      price_id: null,
    }
    if(!org.value){
      regionStore.GetOrganization(props.organization_uid);
      handle_product_list()
    }
    else{
      handle_product_list()
    }
  });

  function do_purchase(){
    loading.value = true;
    purchase.value.price_id = product.value.default_price.id;
    regionStore.MakePurchase(org.value, purchase.value).then((p) => {
      loading.value = false;
      if(p){
      }
    })
  }

</script>

<template>
  <q-card v-if="org" class="text-left">
    <q-card-section class="text-h5 text-primary">
      <q-btn class="absolute-top-right q-ma-sm" icon="close" flat round dense :to="`/organization/${org.uid}`"/>
      {{ org.name }} New Purchase
    </q-card-section>
    <q-card-section v-if="purchase">
      <q-select
          outlined
          v-model="product"
          :options="options"
          label="Product"
          option-label="name"
          option-value="default_price.id"
      />
      <q-input outlined v-model="purchase.qty" label="Quantity" />
    </q-card-section>
    <q-card-actions>
      <q-btn label="Purchase" color="primary" @click="do_purchase" />
    </q-card-actions>
    <q-inner-loading
      :showing="loading"
      label="Please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />
  </q-card>

</template>

<style scoped>

</style>