<template>
  <q-card v-if="activity" class="text-left">
    <q-card-section class="text-h5 text-primary">
      <q-btn v-if="activity.activity_mode === 'template' && (course_uid === 'null' || course_uid === null || course_uid === undefined)" class="absolute-top-right q-ma-sm" icon="close" flat round dense :to="`/template_activities`"/>
      <q-btn v-else class="absolute-top-right q-ma-sm" icon="close" flat round dense :to="`/course/${course_uid}`"/>
      {{ activity.activity_name }}
    </q-card-section>
    <q-tabs
        v-model="tab"
        class="text-grey"
        active-color="primary"
        indicator-color="primary"
        align="justify"
      >
        <q-tab name="details" label="Details" />
        <q-tab v-if="activity.activity_mode!=='template'" name="submissions" label="Grading" />
    </q-tabs>

    <q-separator />

    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="details">
        <q-form @submit.prevent="save_verify">
          <q-card-section align="right">
            <q-select :disable="!can_edit" v-if="activity.activity_mode==='template' && !activity.course_uid" label="Template Visibility" outlined style="min-width: 200px; max-width: 300px;" :options="visibility_options" v-model="visibility"></q-select>
          </q-card-section>

          <div class="row">
            <div class="col-md-6">
              <q-input :disable="!can_edit" :rules="[val => !!val || 'Field is required']" class="q-pa-md" label="Activity Name" v-model="activity.activity_name" outlined></q-input>
              <q-select :disable="activity.uid!==null && activity.uid!==undefined" :rules="[val => !!val || 'Field is required']" class="q-pa-md" label="Activity Experience" v-model="activity.activity_type" :options="activity_types" emit-value outlined map-options>
                <template v-slot:hint>
                  <div v-if="activity.activity_type==='Clinical'">Users create their own patients</div>
                  <div v-if="activity.activity_type==='Inpatient'">Add pre-built patients from your Template Library</div>
                  <div v-if="activity.activity_type==='Outpatient'">Users view appointments, calendar, billing, and patients in an Outpatient setting</div>
                  <div v-if="activity.activity_type==='Simulation'">Users view patients in a simulation setting</div>
                </template>
                <template v-slot:option="scope">
                  <q-item v-bind="scope.itemProps">
                    <q-item-section>
                      <q-item-label>{{ scope.opt.label }}</q-item-label>
                      <q-item-label caption>{{ scope.opt.description }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
              <!--<q-select :rules="[val => !!val || 'Field is required']" v-if="activity.activity_type === 'Inpatient' || activity.activity_type === 'Outpatient'" class="q-pa-md" label="Student Access Type" v-model="activity.assignment_type" :options="assignment_types" outlined></q-select>-->
              <q-checkbox :disable="activity.uid!==null && activity.uid!==undefined"  :rules="[val => !!val || 'Field is required']" v-if="activity.activity_type === 'Inpatient' || activity.activity_type === 'Outpatient'" class="q-pa-md" label="This is a Group Activity" v-model="group_activity" outlined></q-checkbox>
              <q-checkbox :disable="!can_edit" v-if="activity.activity_type === 'Simulation'" class="q-pa-md" label="Allow Students to preview simulation patients" v-model="activity.sim_preview" outlined></q-checkbox>
              <q-select
                :disable="!can_edit"
                class="q-pa-md"
                outlined
                v-model="activity.available_visits"
                multiple
                clearable
                use-chips
                label="Available Visit Types"
                :options="available_visits"
                emit-value
                map-options
                option-value="uid"
                option-label="chart_name"
                hint="Leave blank to include all visit types"
              >
              </q-select>
              <q-toggle
                :disable="!can_edit"
                v-if="activity.activity_mode!=='template'"
                class="q-mt-md"
                name="visibility"
                v-model="activity.visibility"
                :label="activity.visibility ? 'Visible' : 'Hidden'"
              />
              <DateTimeField v-if="activity.activity_mode!=='template' && !activity.visibility" class="q-ma-md" label="Visibility" v-model="activity.activity_start_date"></DateTimeField>
              <DateTimeField v-if="activity.activity_mode!=='template'" class="q-ma-md" label="Due" v-model="activity.activity_end_date"></DateTimeField>
              <div class="q-pa-md">
                Student Instructions
                <q-editor :disable="!can_edit" v-model="activity.student_instructions" min-height="5rem" />
              </div>
              <div class="q-pa-md">
                Instructor Description
                <q-editor :disable="!can_edit" v-model="activity.instructor_description" min-height="5rem" />
              </div>
            </div>
            <div class="col-md-6">
              <q-btn :disable="!can_edit" v-if="activity.activity_type" class="q-ma-md" color="primary" icon="add" @click="add_patient">Add Patient</q-btn>
              <template v-for="(patient, index) in activity.template_patients">
                <q-card v-if="patient.patient_type==='user_creates'" class="q-mt-md col-4 bg-secondary">
                  <q-card-section class="bg-primary text-white" style="border-radius: 6px;">
                    <div class="inline-block q-ml-md vertical-middle"><span class="text-bold">{{ patient.name }}</span>
                    </div>
                    <q-btn v-if="can_edit" color="negative" class="q-ml-md float-right" @click="activity.template_patients.splice(index, 1)">Remove</q-btn>
                  </q-card-section>
                  <q-card-section class="text-white q-pa-sm">
                    User Creates Patient
                  </q-card-section>
                </q-card>
                <PatientBox v-else-if="can_edit" class="q-mt-md" :patient="patient" remove @remove="activity.template_patients.splice(index, 1)"/>
                <PatientBox v-else class="q-mt-md" :patient="patient"/>
              </template>
            </div>
            <q-btn v-if="can_edit" class="q-ma-md" color="secondary" type="submit">Save</q-btn>
            <q-btn v-if="can_edit" class="q-ma-md" color="negative" @click="confirm_delete=true">Delete</q-btn>
            <q-btn v-if="can_edit && activity.activity_mode!=='template' && activity.uid" class="q-ma-md" @click="make_template">Make Activity into Template</q-btn>
          </div>
        </q-form>
      </q-tab-panel>
      <q-tab-panel name="submissions">
        <Submissions :course_uid="course_uid" :activity="activity"></Submissions>
      </q-tab-panel>
    </q-tab-panels>
  </q-card>
  <q-dialog v-model="new_patient_dialog">
    <q-card>
      <q-card-section class="text-h5 text-center q-pt-lg">
        <q-btn class="absolute-top-right q-ma-sm" icon="close" flat round dense @click="new_patient_dialog=false"/>
        New Patient Label
      </q-card-section>
      <q-card-section>
        <q-input class="q-pa-md" label="Patient Name" v-model="new_patient.name" outlined></q-input>
      </q-card-section>
      <q-card-actions>
        <q-btn color="secondary" @click="save_new_patient">OK</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="confirm_no_patients">
    <q-card>
      <q-card-section class="row items-center">
        <span class="q-ml-sm">You have not added any patients. Are you sure you want to continue?</span><br>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn label="No" color="primary" v-close-popup />
        <q-btn label="Yes" flat v-close-popup @click="save" />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="confirm_delete">
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar icon="delete" color="negative" text-color="white" />
        <span class="q-ml-sm">Are you sure you want to delete the Activity: {{activity.activity_name }}?</span><br>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="primary" v-close-popup />
        <q-btn label="Delete" color="negative" v-close-popup @click="regionStore.DeleteActivity(activity); $router.replace(activity.course_uid ? `/course/${activity.course_uid}` : '/template_activities')" />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-inner-loading
      :showing="loading"
      label="Please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />

</template>

<script>
import { ref, reactive, onMounted, nextTick, watch, computed } from 'vue'
import { useUserStore } from "../stores/user";
import { useRegionStore} from "../stores/region";
import { storeToRefs } from 'pinia'
import { useQuasar } from 'quasar'
import PatientSelector from '../components/PatientSelector.vue'
import UsersView from "./UsersView.vue";
import CompactUserCard from "../components/CompactUserCard.vue";
import Activities from "../components/Activities.vue";
import DateTimeField from "../components/DateTimeField.vue";
import Submissions from "../components/Submissions.vue";
import PatientBox from "../components/PatientBox.vue";
import { useRouter } from "vue-router";
import {generate} from "xksuid";
import {useFlowsheetStore} from "../stores/flowsheet";

export default {
  name: "Activity",
  components: {PatientBox, Submissions, DateTimeField, CompactUserCard, UsersView, Activities},
  props: ['uid', 'is_new', 'course_uid', 'template'],
  setup(props){
    const router = useRouter()
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    const regionStore = useRegionStore();
    const flowsheetStore = useFlowsheetStore();
    const { org, activity } = storeToRefs(regionStore)
    const organization_uid = ref(null);
    const course = ref(null);
    const loading = ref(false);
    const tab = ref('details');
    const new_patient_dialog = ref(false);
    const new_patient = ref( { name: '', patient_type: 'user_creates', uid: generate()} );
    const available_visits = ref([]);
    const confirm_delete = ref(false);
    const confirm_no_patients = ref(false);
    const visibility = ref({label: 'Private', value: 'private'});

    const group_activity = computed({get(){
      return activity.value.assignment_type === 'Group';
    }, set(value){
      if(value === true){
        activity.value.assignment_type = 'Group';
      }
      else{
        activity.value.assignment_type = 'Individual';
      }
    }});

    const can_edit = computed(() => {
      if(activity.value.activity_mode!=='template'){
        return true;
      }

      if(activity.value.user_embed && activity.value.user_embed.uid === user.value.uid){
        return true
      }
      if(activity.value.organization_embed && userStore.check_roles(['Organization Admin', 'Organization Content Admin'], activity.value.organization_embed.uid)){
        return true
      }
      if(activity.value.program_embed && userStore.check_roles(['Program Admin', 'Program Content Admin'], org.value.uid, activity.value.program_embed.uid)){
        return true
      }
      if(activity.value.organization_draft_embed && userStore.check_roles(['Organization Admin', 'Organization Content Admin'], activity.value.organization_draft_embed.uid)){
        return true
      }
      if(activity.value.program_draft_embed && userStore.check_roles(['Program Admin', 'Program Content Admin'], org.value.uid, activity.value.program_draft_embed.uid)){
        return true
      }
      if((activity.value.system_activity===true || activity.value.system_draft_activity===true) && userStore.check_roles(['Superuser', 'Content Manager']) ) return true;
      return false;
    });

    onMounted(() => {
      organization_uid.value = props.organization_uid || org.value.uid;
      if(!activity.value || activity.value.uid !== props.uid) {
        loading.value = true;
        if (props.uid) {
          regionStore.GetActivity(props.course_uid, props.uid).then((res) => {
            activity.value = res.activity;
            course.value = res.course;
            if (!activity.value.student_instructions) activity.value.student_instructions = '';
            if (!activity.value.instructor_description) activity.value.instructor_description = '';
            if (activity.value.activity_mode === 'template') tab.value = 'details'
            else tab.value = 'submissions';
            
            if(activity.value.program_embed && activity.value.user_embed){
              visibility.value = {label: `Visible to ${activity.value.program_embed.name}`, value: 'public', program: activity.value.program_embed};
            }
            else if(activity.value.organization_embed && activity.value.user_embed) {
              visibility.value = {
                label: `Visible to ${activity.value.organization_embed.short_name ? activity.value.organization_embed.short_name : activity.value.organization_embed.name}`,
                value: 'public',
                organization: activity.value.organization_embed
              };
            }
            else if(activity.value.user_embed){
              visibility.value = {label: 'Private', value: 'private'};
            }
            else if(activity.value.organization_embed){
              visibility.value = {label: `${activity.value.organization_embed.value.short_name ? activity.value.organization_embed.value.short_name : activity.value.organization_embed.value.name} activity.value`, value: 'organization'}
            }
            else if(activity.value.program_embed){
              visibility.value = {label: `${activity.value.program_embed.name} activity.value`, value: 'program', program: activity.value.program_embed};
            }
            else if(activity.value.organization_draft_embed){
              visibility.value = {label: `Draft ${activity.value.organization_draft_embed.value.short_name ? activity.value.organization_embed.value.short_name : activity.value.organization_embed.value.name} activity.value`, value: 'draft organization'}
            }
            else if(activity.value.program_draft_embed){
              visibility.value = {label: `Draft ${activity.value.program_draft_embed.name} activity.value`, value: 'draft program', program: activity.value.program_embed};
            }
            else if(activity.value.system_activity === true){
              visibility.value = {label: 'System Activity', value: 'system'};
            }
            else if(activity.value.system_draft_activity === true){
              visibility.value = {label: 'Draft System Course', value: 'draft system'};
            }        
            
          })
        } else {
          activity.value = {
            activity_name: "",
            activity_type: "",
            assignment_type: "Individual",
            instructor_description: '',
            student_instructions: '',
            organization_uid: organization_uid.value,
            course_uid: props.course_uid,
            visibility: true,
            sim_preview: false,
            template_patients: [],
            available_visits: [],
            activity_mode: props.template ? 'template' : 'instance',
          }
          tab.value = 'details';
          if(activity.value.activity_mode === 'template'){
            activity.value.user_embed = user.value;
          }
        }
      }
      else{
        if(activity.value.activity_mode === 'template'){
          tab.value = 'details';
        }
        else{
          tab.value = 'submissions';
        }
      }
      flowsheetStore.GetCharts(null, null, null, null, null, null, null, 'published').then(charts => {
        available_visits.value = charts.charts.filter(c => c.uid !== 'patient');
        loading.value = false;
      })

    });

    const $q = useQuasar()

      function add_patient() {
        if(activity.value.activity_type === 'Clinical'){
          new_patient.value.name = `Patient ${activity.value.template_patients.length + 1}`;
          new_patient.value.uid = generate();
          new_patient_dialog.value = true;
        }
        else {
          $q.dialog({
            component: PatientSelector,

            // props forwarded to your custom component
            componentProps: {

              // ...more..props...
            }
          }).onOk((patient) => {
            console.log('OK');
            console.log(patient);
            activity.value.template_patients.push(patient);
          }).onCancel(() => {
            console.log('Cancel')
          }).onDismiss(() => {
            console.log('Called on OK or Cancel')
          })
        }
      }

    const activity_types = computed(() => {
      let types = [
        {label: 'New Patients', value: 'Clinical', description: 'Users Create their own patients'},
        {label: 'Template Patients', value: 'Inpatient', description: 'Add pre-built patients from your Template Library'},
        //{label: 'Medical Office', value: 'Outpatient', description: 'Users view appointments, calendar, billing, and patients in an Outpatient setting'}
      ];
      if(regionStore.CheckPolicy(course, 'simulation')){
        types.push({label: 'Simulation', value: 'Simulation', description: 'Users view patients in a simulation setting'})
      }
      return types;
    });
    function save(){
      loading.value = true;
      
      if(activity.value.activity_mode==='template'){
        //Set the Visibility Options
        activity.value.user_embed = null;
        activity.value.organization_embed = null;
        activity.value.program_embed = null;
        activity.value.organization_draft_embed = null;
        activity.value.program_draft_embed = null;
        activity.value.system_activity = null;
        activity.value.system_draft_activity = null;
        if(visibility.value.value === 'private'){
          activity.value.user_embed = user.value;
        }
        else if(visibility.value.value === 'organization'){
          activity.value.organization_embed = org.value;
        }
        else if(visibility.value.value === 'system'){
          activity.value.system_activity = true;
        }
        else if(visibility.value.value === 'draft system'){
          activity.value.system_draft_activity = true;
        }
        else if(visibility.value.value === 'draft organization'){
          activity.value.organization_draft_embed = org.value;
        }
        else if(visibility.value.value === 'public'){
          activity.value.user_embed = user.value;
          if(visibility.value.organization){
            activity.value.organization_embed = visibility.value.organization;
          }
        }
      }
      
      
      regionStore.UpdateActivity(activity.value).then((a)=>{
        activity.value = a;
        loading.value = false;
        if(props.is_new){
          router.replace(`/course/${a.course_uid}/activity/${a.uid}`);
        }
      });
    }

    const visibility_options = computed(()=> {
      let options = [{label: 'Private', value: 'private'}];
      // Add the current organization
      if(org.value){
        if(userStore.check_roles(['Organization Admin', 'Organization Content Admin'], org.uid)){
          options.push({label: `${org.value.short_name ? org.value.short_name : org.value.name} Patient`, value: 'organization'});
          options.push({label: `Draft ${org.value.short_name ? org.value.short_name : org.value.name} Patient`, value: 'draft organization'});
        }
        // Add the available programs
        const programs = userStore.programs_for_organization(org.uid);
        if(programs.length > 0) {
          for(let program of programs) {
            options.push({label: `Visible to ${program.name}`, value: 'public', program: program});
            if (userStore.check_roles(['Program Admin', 'Program Content Admin'], null, program.uid)) {
              options.push({label: `${program.name} Patient`, value: 'program', program: program});
              options.push({label: `Draft ${program.name} Patient`, value: 'draft program', program: program});
            }
          }
        }
        else{
          options.push({label: `Visible to ${org.value.short_name ? org.value.short_name : org.value.name}`, value: 'public', organization: org.value});
        }
      }
      //Add System Patient
      if(userStore.check_roles(['Superuser', 'Content Manager'])){
        options.push({label: 'Draft System Activity', value: 'draft system'});
        options.push({label: 'System Activity', value: 'system'});
      }
      return options;
    });

    return {
      visibility,
      visibility_options,
      regionStore,
      confirm_delete,
      confirm_no_patients,
      group_activity,
      available_visits,
      new_patient_dialog,
      new_patient,
      router,
      add_patient,
      tab,
      activity,
      loading,
      activity_types,
      assignment_types: ['Individual', 'Group'],
      save,
      save_verify(){
        if(activity.value.template_patients.length === 0){
          confirm_no_patients.value = true;
        }
        else {
          save();
        }
      },
      save_new_patient() {
        activity.value.template_patients.push({...new_patient.value});
        new_patient_dialog.value = false;
      },
      make_template() {
        loading.value = true;
        regionStore.MakeTemplateActivity(activity.value, props.course_uid).then((a) => {
          activity.value = a;
          loading.value = false;
          router.push(`/course/${a.course_uid}/activity/${a.uid}`);
        });
      },
      can_edit
    }
  }
}
</script>

<style scoped>

</style>
