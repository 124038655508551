<template>
  <q-card class="q-ma-lg">
    <q-tabs
        v-model="tab"
        class="text-grey"
        active-color="primary"
        indicator-color="primary"
        align="justify"
      >
        <q-tab name="details" label="Details" />
        <q-tab v-if="patient.patient_type==='template'" name="advanced" label="Advanced Template Options" />
    </q-tabs>
    <q-separator />
    <q-form @submit.prevent="save">
      <q-card-section align="right">
        <q-select v-if="patient.patient_type==='template'" :disable="patient.readonly" label="Template Visibility" outlined style="min-width: 200px; max-width: 300px;" :options="visibility_options" v-model="visibility"></q-select>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <q-tab-panels v-model="tab" animated>
          <q-tab-panel name="details">
            <q-banner v-if="is_new && patient.patient_type==='user_created'" class="bg-accent q-mb-md">
              <p class="text-white">Fill out the details below to create your New Patient. For HIPAA reasons, you are unable to change the Patient's Name. For more information, click here for our <a target="_blank" href="https://help.chartflow.io/articles/students-chart-guide">Student Patient Chart Guide</a></p>
            </q-banner>
            <q-banner v-if="is_new && patient.patient_type==='template'" class="bg-accent text-white q-mb-md">
              <p class="text-white">ChartFlow Patient Templates utilize a relative date & time format. These Templates can be added to an infinite number of Courses & Activities, while preserving the original Template for future use. For more information click here for our <a target="_blank" href="https://help.chartflow.io/articles/patient-templates">Patient Templates Guide</a></p>
            </q-banner>
              <div class="text-center">
                <ProfilePicUploader :disable="patient.readonly" v-model="patient.patient_picture"></ProfilePicUploader>
              </div>
              <q-input class="q-pb-lg" v-if="!is_new" :disable="patient.patient_type==='user_created' || patient.readonly" ref="legalnameref" :rules="[name_validate]" outlined v-model="patient.legal_name" label="Legal Name"></q-input>
              <q-input class="q-pb-lg" :disable="patient.patient_type==='user_created' || patient.readonly" ref="preferrednameref" :rules="[name_validate]" outlined v-model="patient.preferred_name" label="Preferred Name"></q-input>
              <div v-if="patient.patient_type==='user_created'" class="row items-start q-col-gutter-md">
                <q-input :disable="patient.readonly" class="q-pb-lg col-grow" outlined :rules="[val => /^[0-9.]*$/.test(val) || 'must be a positive number']" type="number" v-model.number="age_number" label="Age"></q-input>
                <q-select :disable="patient.readonly" class="q-pb-lg col-4" outlined label="Age Unit" :options="['minutes', 'hours', 'days', 'months', 'years']" v-model="age_unit"></q-select>
              </div>
              <q-input v-else-if="patient.date_of_birth_override && ['individual', 'shared', 'preview'].includes(patient.patient_type)" disable class="q-pb-lg" outlined v-model="patient.date_of_birth_override" label="Date of Birth"></q-input>
              <PatientDateTimeField v-else :disable="patient.readonly" :rules="[value => !!value || 'Date of Birth is Required']" :patient="patient" v-model="patient.date_of_birth" :label="patient.patient_type==='template' ? 'Age' : 'Date of Birth'" default_timeframe="years"></PatientDateTimeField>
              <q-select :disable="patient.readonly" class="q-pb-lg" outlined v-model="patient.legal_sex" label="Legal Sex" :options="['Male', 'Female', 'Other']"></q-select>
              <q-select :disable="patient.readonly" class="q-pb-lg" outlined v-model="patient.sex_at_birth" label="Sex at Birth" :options="['Male', 'Female', 'Other']"></q-select>
              <q-input v-if="!is_new" :disable="patient.readonly" class="q-pb-lg" outlined v-model="patient.gender_identity" label="Gender Identity"></q-input>
              <q-input :disable="patient.readonly" class="q-pb-lg" outlined v-model="patient.code_status" label="Code Status"></q-input>

              <span v-if="patient.patient_type==='template'" class="q-pa-lg">Patient Description</span>
              <q-editor :disable="patient.readonly" v-if="patient.patient_type==='template'" class="q-pa-lg" outlined v-model="patient.patient_description"></q-editor>
          </q-tab-panel>
          <q-tab-panel name="advanced">
                <q-input :disable="patient.readonly" class="q-pb-lg" outlined v-model="patient.mrn" label="Patient MRN" :rules="[val => !!val || 'Field is required']"></q-input>
                <q-input :disable="patient.readonly" class="q-pb-lg" outlined v-model="patient.date_of_birth_override" label="Date of Birth Override"></q-input>
                <q-input :disable="patient.readonly" class="q-pb-lg" outlined v-model="patient.fixed_start_time" label="Fixed Start Time" mask="##:##"></q-input>
          </q-tab-panel>
        </q-tab-panels>
    </q-card-section>
    <q-card-actions>
        <q-btn class="q-ma-sm" v-if="!patient.readonly" color="secondary" label="Save" type="submit"></q-btn>
        <q-btn class="q-ma-sm" v-if="!patient.readonly && patient.patient_type==='template'" color="negative" label="Delete" @click="confirm_delete=true"></q-btn>
    </q-card-actions>
    </q-form>
    <q-inner-loading
      :showing="loading"
      label="Please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />
    <q-dialog v-model="confirm_delete">
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="delete" color="negative" text-color="white" />
          <span class="q-ml-sm">Are you sure you want to delete the patient: {{ patient.name }}?</span><br>
          <span>This will delete all Patient Data</span>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup />
          <q-btn label="Delete" color="negative" v-close-popup @click="patientStore.DeletePatient(patient); $router.replace(`/template_patients`)" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-btn class="absolute-top-right q-ma-sm" icon="close" flat round dense @click="$router.back()"/>
  </q-card>
</template>

<script>
import {usePatientStore} from "../../stores/patient";
import {computed, onMounted, ref, watch} from 'vue';
import PatientDateTimeField from "../../components/PatientDateTimeField.vue";
import { date } from 'quasar'
import ProfilePicUploader from "../../components/ProfilePicUploader.vue";
import { storeToRefs } from 'pinia'
import {useRegionStore} from "../../stores/region";
import {useUserStore} from "../../stores/user";
import {calc_fields} from "@/util";

//patient_type: one of 'individual', 'shared', 'scene', 'preview', 'template'
export default {
  name: "PatientDetails",
  components: {ProfilePicUploader, PatientDateTimeField},
  props: ['template', 'course_uid', 'activity_uid', 'is_new', 'name', 'template_patient_id'],
  setup(props){
    const loading = ref(false);
    const legalnameref = ref(null);
    const preferrednameref = ref(null);
    const patientStore = usePatientStore();
    const regionStore = useRegionStore();
    const userStore = useUserStore();
    const {user} = storeToRefs(userStore);
    const {org} = storeToRefs(regionStore);
    const {patient} = storeToRefs(patientStore);
    const age_number = ref(null);
    const age_unit = ref('years');
    const tab = ref('details');
    const confirm_delete = ref(false);
    if(!patient.value || props.is_new) {
      patient.value = {
        epoch_datetime: date.formatDate(new Date(), 'YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
        template_patient_id: props.template_patient_id ? props.template_patient_id : null,
        course_uid: props.course_uid,
        activity_uid: props.activity_uid,
        organization_uid: org.uid,
        patient_type: 'user_created',
        patient_picture: null,
        preferred_name: props.name ? props.name : '',
        legal_name: '',
        date_of_birth: '',
        legal_sex: '',
        sex_at_birth: '',
        gender_identity: '',
        patient_description: '',
        date_of_birth_override: '',
        fixed_start_time: '',
        mrn: Math.floor(Math.random() * 999999),
        scenes: []
      }
    }
    if(patient.value.gender_identity === undefined) patient.value.gender_identity = ''
    if(patient.value.patient_picture === undefined) patient.value.patient_picture = ''
    if(patient.value.preferred_name === undefined) patient.value.preferred_name = ''
    if(patient.value.date_of_birth === undefined) patient.value.date_of_birth = ''
    if(patient.value.legal_name === undefined) patient.value.legal_name = ''
    if(patient.value.legal_sex === undefined) patient.value.legal_sex = ''
    if(patient.value.sex_at_birth === undefined) patient.value.sex_at_birth = ''
    if(props.template) patient.value.patient_type = 'template';
    const visibility = ref({label: 'Private', value: 'private'})

    const visibility_options = computed(()=> {
      let options = [{label: 'Private', value: 'private'}];
      // Add the current organization
      if(org.value){
        if(userStore.check_roles(['Organization Admin', 'Organization Content Admin'], org.uid)){
          options.push({label: `${org.value.short_name ? org.value.short_name : org.value.name} Patient`, value: 'organization'});
          options.push({label: `Draft ${org.value.short_name ? org.value.short_name : org.value.name} Patient`, value: 'draft organization'});
        }
        // Add the available programs
        const programs = userStore.programs_for_organization(org.uid);
        if(programs.length > 0) {
          for(let program of programs) {
            options.push({label: `Visible to ${program.name}`, value: 'public', program: program});
            if (userStore.check_roles(['Program Admin', 'Program Content Admin'], null, program.uid)) {
              options.push({label: `${program.name} Patient`, value: 'program', program: program});
              options.push({label: `Draft ${program.name} Patient`, value: 'draft program', program: program});
            }
          }
        }
        else{
          options.push({label: `Visible to ${org.value.short_name ? org.value.short_name : org.value.name}`, value: 'public', organization: org.value});
        }
      }

      //Add System Patient
      if(userStore.check_roles(['Superuser', 'Content Manager'])){
        options.push({label: 'Draft System Patient', value: 'draft system'});
        options.push({label: 'System Patient', value: 'system'});
      }
      return options;
    });

    function set_dob(){
      switch(age_unit.value){
        case 'years':
          patient.value.date_of_birth = -(age_number.value * 365 * 24 * 60);
          break;
        case 'months':
          patient.value.date_of_birth = -(age_number.value * 30 * 24 * 60);
          break;
        case 'weeks':
          patient.value.date_of_birth = -(age_number.value * 7 * 24 * 60);
          break;
        case 'days':
          patient.value.date_of_birth = -(age_number.value * 24 * 60);
          break;
        case 'hours':
          patient.value.date_of_birth = -(age_number.value * 60);
          break;
        case 'minutes':
          patient.value.date_of_birth = -age_number.value;
          break;
      }

    }

    watch(age_number, (new_value, old_value) => {
      if(new_value !== undefined && new_value !== null) {
        set_dob();
      }
    });
    watch(age_unit, (new_value, old_value) => {
      if(new_value !== undefined && new_value !== null) {
        set_dob();
      }
    });

    onMounted(()=>{
      if(patient.value.date_of_birth) {
        let out = calc_fields(patient.value.date_of_birth);
        age_number.value = out.time_number;
        age_unit.value = out.timeframe;
      }
      if(patient.value.program_embed && patient.value.user_embed){
        visibility.value = {label: `Visible to ${patient.value.program_embed.name}`, value: 'public', program: patient.value.program_embed};
      }
      else if(patient.value.organization_embed && patient.value.user_embed) {
        visibility.value = {
          label: `Visible to ${patient.value.organization_embed.short_name ? patient.value.organization_embed.short_name : patient.value.organization_embed.name}`,
          value: 'public',
          organization: patient.value.organization_embed
        };
      }
      else if(patient.value.user_embed){
        visibility.value = {label: 'Private', value: 'private'};
      }
      else if(patient.value.organization_embed){
        visibility.value = {label: `${patient.value.organization_embed.value.short_name ? patient.value.organization_embed.value.short_name : patient.value.organization_embed.value.name} patient.value`, value: 'organization'}
      }
      else if(patient.value.program_embed){
        visibility.value = {label: `${patient.value.program_embed.name} patient.value`, value: 'program', program: patient.value.program_embed};
      }
      else if(patient.value.organization_draft_embed){
        visibility.value = {label: `Draft ${patient.value.organization_draft_embed.value.short_name ? patient.value.organization_embed.value.short_name : patient.value.organization_embed.value.name} patient.value`, value: 'draft organization'}
      }
      else if(patient.value.program_draft_embed){
        visibility.value = {label: `Draft ${patient.value.program_draft_embed.name} patient.value`, value: 'draft program', program: patient.value.program_embed};
      }
      else if(patient.value.system_patient === true){
        visibility.value = {label: 'System Patient', value: 'system'};
      }
      else if(patient.value.system_draft_patient === true){
        visibility.value = {label: 'Draft System Patient', value: 'draft system'};
      }
    });

    return {
      tab,
      loading,
      age_number,
      age_unit,
      visibility,
      legalnameref,
      preferrednameref,
      patient,
      user,
      patientStore,
      visibility_options,
      confirm_delete
    }
  },
  methods: {
    save() {
      this.loading = true;
      if(this.patient.patient_type==='template'){
        //Set the Visibility Options
        this.patient.user_embed = null;
        this.patient.organization_embed = null;
        this.patient.program_embed = null;
        this.patient.organization_draft_embed = null;
        this.patient.program_draft_embed = null;
        this.patient.system_patient = null;
        this.patient.system_patient_draft = null;
        if(this.visibility.value === 'private'){
          this.patient.user_embed = this.user;
        }
        else if(this.visibility.value === 'organization'){
          this.patient.organization_embed = this.org;
        }
        else if(this.visibility.value === 'program'){
          this.patient.program_embed = this.visibility.program;
        }
        else if(this.visibility.value === 'system'){
          this.patient.system_patient = true;
        }
        else if(this.visibility.value === 'draft system'){
          this.patient.system_draft_patient = true;
        }
        else if(this.visibility.value === 'draft organization'){
          this.patient.organization_draft_embed = this.org;
        }
        else if(this.visibility.value === 'draft program'){
          this.patient.program_draft_embed = this.visibility.program;
        }
        else if(this.visibility.value === 'public'){
          this.patient.user_embed = this.user;
          if(this.visibility.program){
            this.patient.program_embed = this.visibility.program;
          }
          else{
            this.patient.organization_embed = this.visibility.organization;
          }
        }
      }

      if(this.course_uid) this.patient.course_uid=this.course_uid;
      if(this.activity_uid) this.patient.activity_uid=this.activity_uid;
      const that = this;
      this.patientStore.SavePatient(this.patient).then((data) => {
        setTimeout(function() {
          that.$router.push(`/patient/${data.patient.uid}`);
          that.loading = false;
        }, 1000);

      })
    },
    name_validate(){
      if(this.patient.legal_name || this.patient.preferred_name){
        if(this.legalnameref) this.legalnameref.resetValidation();
        if(this.preferrednameref) this.preferrednameref.resetValidation();
        return true;
      }
      return 'Either Legal Name or Preferred Name is required';
    }
  }
}
</script>

<style scoped>

</style>
