<template>
  <span v-if="edit===false">{{ date_string(patient, modelValue) }}</span>
  <template v-else>
    <div
        v-if="patient.patient_type==='template' || patient.patient_type==='scene'"
        class="row items-start q-col-gutter-md"
    >
      <q-input :error=error :error-message="errorMessage" class="col col-grow" :readonly="readonly" :disable="disable" :bg-color="bgColor" :rules="[].concat(rules?rules:[], [val => /^[0-9.]*$/.test(val) || 'must be a positive number'])" outlined :dense="dense" :label="label" type="number" v-model.number="time_number" @update:model-value="update_template($event, timeframe)">
      </q-input>
      <q-select class="col-4" outlined :readonly="readonly" :disable="disable" :bg-color="bgColor" :dense="dense" label="timeframe" :options="options" style="min-width: 225px" :model-value="timeframe" @update:model-value="update_template(time_number, $event)">
        <template v-slot:append v-if="hide_append===undefined">
          <span :class="['text-subtitle1', disable===undefined ? 'text-black' : 'text-grey-6', 'q-pt-sm']">before start</span>
        </template>
      </q-select>
    </div>
    <div v-else class="row items-start q-col-gutter-md">
      <q-input :error=error :error-message="errorMessage" :rules="[].concat(rules?rules:[], [myrule])" class="col-8" :readonly="readonly" :disable="disable" :bg-color="bgColor" outlined :dense="dense" :label="`${clabel}Date`" v-model="field_date" style="min-width: 150px"
        @focus="handle_focus($event)"
        @blur="update_datetime"
        >
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy v-model="visible" persistent no-focus ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
              <q-date v-model="field_date" today-btn mask="DD MMM YYYY">
                <div class="row items-center justify-end">
                  <q-btn @click="close_date()" label="Close" color="primary" flat />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>

      </q-input>
      <q-input ref="timefieldref" :rules="[val => !val || !!field_date || 'Time must have a date']" class="col-4" outlined :readonly="readonly" :disable="disable" :bg-color="bgColor" :dense="dense" :label="`${clabel}Time`" mask="##:##" v-model="field_time" @blur="update_datetime" style="min-width: 150px">
      </q-input>
    </div>
  </template>
</template>

<script>
import { ref } from 'vue'
import { date } from 'quasar'
import {
  datetime_to_epoch_offset,
  datetimedmy_to_epoch_offset,
  date_string,
  time_string,
  epoch_offset_to_datetime, calc_fields
} from "../util";

export default {
  name: "PatientDateTimeField",
  props: ['label','dense', 'bgColor', 'modelValue', 'template', 'patient', 'edit', 'rules', 'readonly', 'disable', 'error', 'error-message', 'default_timeframe', 'hide_append'],
  emits: ['update:modelValue'],
  setup(props){
    const options= ['minutes', 'hours', 'days', 'weeks', 'months', 'years'];
    const time_number = ref('');
    const timeframe = ref('');
    const timefieldref = ref(null);
    const field_date = ref('');
    const field_time = ref('');

    return {
      timefieldref,
      time_number,
      timeframe,
      field_date,
      field_time,
      calc_fields,
      date_string,
      options,
      visible: ref(false),
      closing: ref(false),
      open_from_focus: ref(false),
      has_focus: ref(false),
    }
  },
  computed: {
    clabel(){
      return this.label ? this.label + ' ' : ''
    },
  },
  watch: {
    modelValue: {
      handler(value) {
        if (value === null || value === undefined || value === ''){
          this.time_number = '';
          this.timeframe = this.default_timeframe || 'minutes';
          this.field_date = '';
          this.field_time = '';
          return;
        }
        this.timeframe = this.calc_fields(value).timeframe;
        this.time_number = this.calc_fields(value).time_number;
        this.field_date = date.formatDate(epoch_offset_to_datetime(this.patient, value), 'DD MMM YYYY')
        this.field_time = time_string(this.patient, value);
      },
      immediate: true
    }
  },
  methods:{
    update_template(time_number, timeframe){
      if(time_number === null || time_number === undefined || time_number === '') {
        this.$emit('update:modelValue', null);
        return;
      }
      if(isNaN(time_number)) return;
      this.time_number = time_number;
      this.timeframe = timeframe;
      this.$emit('update:modelValue', this.calc_time(this.time_number, this.timeframe));
    },
    update_datetime(){
      if(this.field_date) {
        console.log('update_datetime');
        let d = ''
        //const d = this.field_date + ' ' + (this.field_time ? this.field_time : '00:00');
        const parts = this.field_date.split(' ');
        d = parts[0] + ' ' + parts[1].charAt(0).toUpperCase() + parts[1].slice(1).toLowerCase() + ' ' + parts[2] + ' ' + (this.field_time ? this.field_time : '00:00');
        this.$emit('update:modelValue', datetimedmy_to_epoch_offset(this.patient, d));
        this.timefieldref.resetValidation();
      }
    },
    calc_time(time_number, timeframe){
      switch(timeframe){
        case 'minutes':
          return -time_number;
        case 'hours':
          return -time_number * 60;
        case 'days':
          return -time_number * 60 * 24;
        case 'weeks':
          return -time_number * 60 * 24 * 7;
        case 'months':
          return -time_number * 60 * 24 * 30;
        case 'years':
          return -time_number * 60 * 24 * 365;
      }
    },
    handle_focus(e){
    },
    close_date(){
      this.closing = true;
      this.visible = false
    },
    myrule(val){
      this.update_datetime();
      return true;
    }
  }
}
</script>

<style scoped lang="scss">
.dtfield{
  border: 1px solid #ccc;
}

.dtfield:hover{
  border-color: #000;
}

.dtfield:focus-within{
  border-color: $primary;
  border-width: 2px;
}

</style>
