<template>
    <BarcodeScanner v-if="$route.path.endsWith('mar')" @barcode="barcode($event)"></BarcodeScanner>
    <div class="row q-pa-md">
      <div class="col-6 text-h5">
        {{ flowsheet.flowsheet_name }}
      </div>
      <div v-if="data" class="col-3">
      </div>
      <div class="col-3 text-right">
        <q-btn color="secondary" round icon="chevron_left" @click="back"></q-btn>
        <q-btn icon="event" flat color="primary" :label="date_string(patient, date_start)">
          <q-popup-proxy @before-show="updateProxy" cover transition-show="scale" transition-hide="scale">
            <q-card v-if="['template', 'scene'].includes(patient.patient_type)" class="q-pa-md">
              <PatientDateTimeField v-model="proxyDate" :patient="patient"></PatientDateTimeField>
              <q-card-actions>
                <q-btn label="Cancel" color="primary" flat v-close-popup />
                <q-btn label="OK" color="primary" flat v-close-popup @click="update_start"/>
              </q-card-actions>
            </q-card>
            <q-date v-else v-model="field_date" mask="DD MMM YYYY" :options="invalid_dates">
              <div class="row items-center justify-end q-gutter-sm">
                <q-btn label="Cancel" color="primary" flat v-close-popup />
                <q-btn label="OK" color="primary" flat @click="update_start" v-close-popup />
              </div>
            </q-date>
          </q-popup-proxy>
        </q-btn>
        <q-btn color="secondary" round icon="chevron_right" @click="next" :disabled="!can_next"></q-btn>
      </div>
    </div>

    <q-form @submit="save">
      <q-markup-table separator="vertical" class="timegrid-table" ref="table" wrap-cells>
        <thead>
          <tr>
            <th style="max-width: 200px;"></th>
            <th v-for="timecol in timecols">
              <template v-if="!['template', 'scene'].includes(patient.patient_type)">
                {{ time_string(patient, timecol) }}
              </template>
              <template v-else>
                {{ date_string(patient, timecol) }}
              </template>
            </th>
          </tr>
        </thead>
      <draggable v-model="flowsheet.fields" item-key="uid" group="fields" :disabled="!editing" tag="tbody" @sort="handlefields($event)">
        <template #item="{element}">
          <tr
              v-if="element.data_type === 'expansion'"
              class="bg-accent text-white text-bold"
              :class="{ 'active-row': active_field ? element.uid === active_field.uid : false}"
              @click="$emit('selected', element); element.visible = !element.visible; update_element_visibility(element)">
            <td :colspan=" 1 + timecols.length + (new_mode && newcolumn ? 1 : 0)">
              {{ element.field_name }} <q-icon :name="element.visible ? 'expand_less' : 'expand_more'"></q-icon>
            </td>
          </tr>
          <tr v-else-if="!['Advanced', 'Lookup Advanced'].includes(element.data_type)" :class="{ 'active-row': active_field ? element.uid === active_field.uid : false, 'hidden': element.visible === false ? true : false}" @click="$emit('selected', element)">
            <td style="max-width: 200px;">{{ element.field_name }}</td>
            <td v-for="d in data.times">
              <component :edit="edit_mode === d" outlined dense :is="lookup(element.data_type)" v-model="d[element.uid]" :patient="patient" :field="element"></component>
              <PatientHelp :element="element"></PatientHelp>
            </td>
            <td v-if="new_mode && newcolumn" style="min-width: 300px;">
              <component :edit="true" outlined dense :is="lookup(element.data_type)" v-model="newcolumn[element.uid]" :patient="patient" :field="element"></component>
              <PatientHelp :element="element"></PatientHelp>
            </td>
          </tr>
          <template v-else>
            <tr data-draggable="true" :class="{ 'active-row': active_field ? element.uid === active_field.uid : false, 'hidden': element.visible === false ? true : false}" @click="$emit('selected', element)">
              <td style="max-width: 200px;">
                <span class="text-bold">{{ element.field_name }}&nbsp;</span>
                <q-btn v-if="!readonly" icon="add" round size="sm" color="accent" @click="add_med(element)"></q-btn>
              </td>
              <td v-for="d in timecols"></td>
            </tr>
            <template v-for="entry in get_entries(element)">
              <tr v-for="{obj, field_id} in sorted_data(data[entry])">
                <td style="max-width: 200px;">
                  <template v-if="element.data_type==='Lookup Advanced'">
                    <TipTap v-model="flowsheet.lookup_data[obj.lookup.lookup_type].advanced_render" :edit="false" :lookup_type="flowsheet.lookup_data[obj.lookup.lookup_type]" :data="obj"></TipTap>
                  </template>
                  <template v-else>
                    <TipTap v-if="element.advanced_render" v-model="element.advanced_render" :lookup_type="element" :edit="false" :data="obj"></TipTap>
                    <template v-else v-for="field in get_fields(element, obj)">
                      <span class="text-bold">{{field.field_name}}:&nbsp;</span>
                      <component :edit="false" outlined dense :is="lookup(field.data_type)" v-model="obj[field.uid]" :patient="patient" :field="field"></component>
                      <br>
                    </template>
                  </template>
                  <q-btn v-if="!readonly && can_edit(obj.user_embed.uid) && !obj.read_only" color="grey-4" round size="sm" class="q-ma-sm" @click="select_med(element.data_type==='Lookup Advanced' ? flowsheet.lookup_data[obj.lookup.lookup_type] : element, obj, field_id)">
                    <q-icon class="text-primary" name="edit"></q-icon>
                  </q-btn>
                  <q-btn v-if="['template', 'scene'].includes(patient.patient_type) && !readonly && can_edit(obj.user_embed.uid) && !obj.read_only" color="negative" round size="sm" class="q-ma-sm" @click="delete_advanced(element.data_type==='Lookup Advanced' ? flowsheet.lookup_data[obj.lookup.lookup_type] : element, field_id, obj); save();">
                    <q-icon class="text-white" name="close"></q-icon>
                  </q-btn>
                </td>
                <template v-for="t in timecols">
                  <td @click="add_action(t, element, field_id, flowsheet.lookup_data.medication.actions.find(f => f.action_name === 'Given'), obj)" style="cursor: pointer">
                      <template v-for="d in get_values(t, entry, field_id)">
                        <template
                          v-if="d[entry] && d[entry][field_id]"
                          v-for="(action_data, action_uid) in d[entry][field_id]"
                        >
                          <q-chip
                              v-if="get_action(element, obj, action_uid)"
                              :removable="!readonly && can_edit(obj.user_embed.uid) && !obj.read_only"
                              :clickable="get_action(element, obj, action_uid).fields.length > 0 || ![null, undefined].includes(get_action(element, obj, action_uid).flowsheet)"
                              @remove="delete_action(d, element, field_id, action_uid, obj); save();"
                              @click.prevent.stop.capture="select_edit_time(t); select_action(d, element, field_id, get_action(element, obj, action_uid), obj, readonly || obj.read_only || !can_edit(obj.user_embed.uid), {ok: do_save, show_timestamp: true} );"
                          >
                            <!--<TipTap v-if="get_action(element, obj, action_uid).advanced_render" v-model="get_action(element, obj, action_uid).advanced_render" :lookup_type="get_action(element, obj, action_uid)" :edit="false" :data="d[element.uid][field_id][action_uid]"></TipTap>-->
                            <q-icon v-if="get_action(element, obj, action_uid).fields.length > 0 || get_action(element, obj, action_uid).flowsheet" name="comment" class="q-pr-sm"></q-icon>
                            <span>{{get_action(element, obj, action_uid).action_name}}</span>
                          </q-chip>
                        </template>
                      </template>
                      <template v-if="get_values(t, entry, field_id).length === 0 && calc_due(t, element, obj) && !readonly">
                        <q-btn-dropdown label="Due" color="accent" @click.stop.prevent>
                          <q-list dense>
                            <q-item clickable v-close-popup v-for="action in get_actions(element, obj)" @click="add_action(t, element, field_id, action, obj)">
                              <q-item-section>
                                <q-item-label>
                                  {{action.action_name}}
                                </q-item-label>
                              </q-item-section>
                            </q-item>
                          </q-list>
                        </q-btn-dropdown>
                      </template>
                  </td>
                </template>
              </tr>
            </template>
          </template>
        </template>
      </draggable>

      </q-markup-table>
    </q-form>
</template>

<script>
import draggable from 'vuedraggable'
import PatientDateTimeField from "../PatientDateTimeField.vue";
import {usePatientData} from "../../composables/patient_data";
import {ref, toRefs, toRef, reactive, onMounted, watchEffect, nextTick, provide, computed} from "vue";
import {useUserStore} from "../../stores/user";
import {
  epoch_offset_to_datetime,
  date_time_string,
  datetime_to_epoch_offset,
  date_string,
  time_string,
  frequencies,
  datetimedmy_to_epoch_offset
} from "../../util";
import {date, Notify} from "quasar";
import AdvancedForm from "./AdvancedForm.vue";
import TipTap from "../TipTap.vue";
import PatientHelp from "./PatientHelp.vue";
import BarcodeScanner from "../BarcodeScanner.vue";

export
default {
  name: "TimeGrid",
  emits: ["selected"],
  props: ["flowsheet", "editing", "active_field", "data", "new_mode"],
  components: {
    PatientHelp,
    TipTap,
    AdvancedForm,
    PatientDateTimeField,
    draggable,
    BarcodeScanner
  },
  setup(props) {
    const {
      patient,
      newcolumn,
      advanced_obj,
      advanced_element,
      new_ele,
      new_mode,
      edit_mode,
      readonly,
      patientStore,
      userStore,
      viewing_visit,
      start_advanced_add,
      new_entry,
      save,
      edit,
      select_action,
      lookup,
      can_edit,
      get_fields,
      get_actions,
      u_error,
      delete_data,
      confirm_delete,
      delete_holder,
      delete_confirmed,
      open_advanced,
      delete_advanced,
      delete_action,
      get_action,
      get_entries,
      reset_data
    } = usePatientData(props);
    const timecols = ref([]);

    const date_start = ref(null);
    const proxyDate = ref(null);
    const field_date = ref('');

    if(['template', 'scene'].includes(patient.value.patient_type)) {
      date_start.value = -24*60;
    } else {
      date_start.value = datetime_to_epoch_offset(patientStore.patient, date.adjustDate(patientStore.patient_time(new Date()), {hour: 0, minute: 0, second: 0, millisecond: 0}));
    }

    function set_timecols() {
      timecols.value = [];
      for (let i = date_start.value; i < date_start.value + 24 * 60; i += 60) {
        timecols.value.push(i);
      }
    }
    set_timecols()

    provide('flowsheet', toRef(props, 'flowsheet'));

    watchEffect(() => {
      for (let fieldIndex in props.flowsheet.fields) {
        let field = props.flowsheet.fields[fieldIndex];
        if (field.data_type === "expansion") {
          field.visible = false;
          nextTick(() => {
            update_element_visibility(field);
          })
        }
      }
    });

    function select_edit_time(t) {
        let found = props.data.times.find(f => f.timestamp === t);
        if(found){
          edit(found);
          newcolumn.value = null;
        }
        else if(!newcolumn.value || newcolumn.value.timestamp !== t){
          newcolumn.value = {
            user_embed: userStore.user,
            timestamp: t
          }
          edit_mode.value = null;
        }
        return found || newcolumn.value;
    }

    const update_element_visibility = function (element) {
      let marking = false;
      for (let i = 0; i < props.flowsheet.fields.length; i++) {
        if (props.flowsheet.fields[i].uid === element.uid) {
          marking = true;
          continue;
        }
        if (marking === true) {
          if (props.flowsheet.fields[i].data_type === 'expansion') {
            marking = false;
            break;
          }
          props.flowsheet.fields[i].visible = element.visible;
        }
      }

    }

    function do_save(){
      save();
    }

    function add_action(t, element, field_id, action, obj){
      if(readonly.value){
        return;
      }
      let edit_time = select_edit_time(t);
      select_action(edit_time, element, field_id, action, obj, false, {ok: do_save, show_action: true, show_timestamp: true});
    }

    const can_next = computed(() => {
        if(['template', 'scene'].includes(patient.value.patient_type)) {
          if(date_start.value >= -24*60) {
            return false;
          }
        }
        else{
          if(date_start.value >= datetime_to_epoch_offset(patientStore.patient, date.adjustDate(patientStore.patient_time(new Date()), {hour: 0, minute: 0, second: 0, millisecond: 0}))) {
            return false;
          }
        }
        return true
    });

    return {
      invalid_dates (d) {
        const d_obj = date.extractDate(d, "YYYY/MM/DD");
        return d_obj <= patientStore.patient_time(new Date())
      },
      updateProxy(){
        proxyDate.value = date_start.value;
        field_date.value = date.formatDate(epoch_offset_to_datetime(patient.value, proxyDate.value), 'DD MMM YYYY')

      },
      update_start(){
        date_start.value = proxyDate.value;
        if(!['template', 'scene'].includes(patient.value.patient_type)) {
          let d = ''
          //const d = this.field_date + ' ' + (this.field_time ? this.field_time : '00:00');
          const parts = field_date.value.split(' ');
          d = parts[0] + ' ' + parts[1].charAt(0).toUpperCase() + parts[1].slice(1).toLowerCase() + ' ' + parts[2] + ' 00:00';
          date_start.value = datetimedmy_to_epoch_offset(patient.value, d);
        }

        set_timecols();
      },
      proxyDate,
      field_date,
      do_save,
      select_edit_time,
      can_next,
      back(){
        date_start.value -= 24*60;
        set_timecols();
      },
      next(){
        date_start.value += 24*60;
        set_timecols();
      },
      barcode: function(evt){
        console.log("barcode: ", evt);
        const split = evt.split(':');
        if (split.length === 2 && split[0] === 'p') {
          const patient_id = split[1];
          if(patient_id !== patient.value.mrn.toString()){
            alert(`Wrong Patient!\n\nExpected: ${patient.value.mrn}\n\nScanned: ${patient_id}`);
          }
          else {
            Notify.create({
                        color: 'info',
                        message: `Patient ${patient.value.name} Barcode Scanned!`,
                        icon: "document_scanner"
                      })
            router.push(`/patient/${patient.value.uid}/flowsheet/mar`);
          }
        }

        if(props.data.medication){
          for(let m in props.data.medication){
            let med = props.data.medication[m]
            if(!med.lookup.data.medication_manufacturer){
              continue;
            }
            console.log(med);
            for (let manufacturer of med.lookup.data.medication_manufacturer){
              if(String(manufacturer.barcode) === evt){
                console.log("Matched Barcode");
                let timestamp = 0
                if(!["template", "scene"].includes(patientStore.patient.patient_type)){
                  timestamp = datetime_to_epoch_offset(patientStore.patient, patientStore.patient_time(new Date()));
                }

                //get element from flowsheet.fields
                let element = null;
                for(let f of props.flowsheet.fields){
                  if(f.lookup_type && f.lookup_type.includes("medication")){
                    element = f;
                    break;
                  }
                }

                add_action(timestamp, element, m, props.flowsheet.lookup_data.medication.actions.find(f => f.action_name === 'Given'), med)
              }
            }
          }
        }

      },
      add_med(element){
        let timestamp = datetime_to_epoch_offset(patientStore.patient, patientStore.patient_time(new Date()));
        if(["template", "scene"].includes(patientStore.patient.patient_type)){
          timestamp = timecols.value[0]
        }

        select_edit_time(timestamp);
        start_advanced_add(element, do_save);
      },
      add_action,
      select_med(element, obj, field_id){
        select_edit_time(obj.timestamp);
        open_advanced(element, obj, field_id, do_save);
      },
      handlefields(evt) {
        console.log(evt);
        console.log(props.flowsheet.fields[evt.newIndex])
        if (props.flowsheet.fields[evt.newIndex].entity_type === 'grouping') {
          update_element_visibility(props.flowsheet.fields[evt.newIndex])
        }
      },
      calc_due(t, element, obj){
        let lookup_type = props.flowsheet.lookup_data[obj.lookup.lookup_type];
        //console.log(t, lookup_type, obj);
        //find Frequency
        let frequency = lookup_type.fields.find(f => f.data_type === 'Frequency')
        let frequency_uid = frequency ? frequency.uid : null;

        //Find Schedule
        let schedule = lookup_type.fields.find(f => f.data_type === 'Schedule')
        let schedule_uid = schedule ? schedule.uid : null;

        //Check Schedule
        if(schedule_uid){
          let schedule = obj[schedule_uid];
          let ts = parseInt(time_string(patient.value, t).split(':')[0]);

          if(schedule){
            let has_s = false;
            for(let s of schedule){
              if(parseInt(s.split(':')[0]) === ts){
                return true;
              }
              if(s){
                has_s = true;
              }
            }
            if(has_s){
              return false;
            }
          }
        }

        //Check Frequency
        if(frequency_uid) {
          let frequency = obj[frequency_uid];
          if (frequency) {
            let frequency_value = frequencies[frequency.frequency];
            if (Math.floor(t / frequency_value) > Math.floor((t - 60) / frequency_value)) {
              return true;
            }
          }
        }
        return false
      },
      get_values(t, entry, field_id) {
        let values = [];
        for(let d of props.data.times){
          if(d.timestamp >= t && d.timestamp < t + 60 && d[entry] && d[entry][field_id]){
            values.push(d);
          }
        }
        return values;
      },
      sorted_data(data){
        let sorted = [];
        for(let field_id in data){
          let obj = data[field_id];
          sorted.push({field_id, obj});
        }
        return sorted.sort((a, b) => {
          if(a.obj.timestamp < b.obj.timestamp){
            return 1;
          }
          if(a.obj.timestamp > b.obj.timestamp){
            return -1;
          }
          return 0;
        });
      },
      update_element_visibility,
      u_error,
      patient,
      new_entry,
      new_ele,
      date_string,
      time_string,
      date_time_string,
      datetime_to_epoch_offset,
      new_mode,
      edit_mode,
      patientStore,
      start_advanced_add,
      userStore,
      newcolumn,
      advanced_obj,
      advanced_element,
      save,
      edit,
      select_action,
      lookup,
      can_edit,
      get_fields,
      get_actions,
      get_action,
      delete_data,
      confirm_delete,
      delete_holder,
      delete_confirmed,
      readonly,
      delete_advanced,
      delete_action,
      open_advanced,
      get_entries,
      timecols,
      date_start,
      reset_data
    }
  },
  watch: {
    "flowsheet": function () {
      this.new_mode = false;
      this.edit_mode = null;
    }
  }
}
</script>

<style lang="scss">
  .timegrid-table {
    max-height: calc(100vh - 100px);
  }
  /*.timegrid-table tbody tr:nth-child(even) td:first-child{
    background-color: #ffffff;
  }*/

  .active-row {
    background-color: $positive !important;
  }

  .timegrid-table tr td:first-child{
    position: sticky;
    z-index: 1;
    left: 0;
  }

.timegrid-table tr th{
    position: sticky;
    left: 0;
  }


  .timegrid-table tr th:first-child{
    z-index: 2;
  }

  th .q-field--error .q-field__bottom{
    color: #ee929c !important;
  }
</style>
