<template>
  <q-card v-if="organization" class="text-left">
    <q-card-section clickable to="/organization" class="text-h5 text-primary">
      <q-btn v-if="userStore.check_roles(['Superuser', 'Support'])" class="absolute-top-right q-ma-sm" icon="close" flat round dense to="/organization"/>
      {{ organization.name }}
    </q-card-section>
    <q-tabs
          v-model="tab"
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="justify"
        >
          <q-tab name="details" label="Details" />
          <q-tab v-if="organization.uid && organization.organization_type!=='Bookstore' && userStore.check_roles(['Superuser', 'Support']) && organization.billing_level==='Organization'" name="licenses" label="Licenses" />
          <q-tab v-if="organization.uid && organization.use_saml" name="saml" label="SAML" />
          <q-tab v-if="organization.uid && organization.organization_type!=='Bookstore'" name="campuses" label="Campuses" />
          <q-tab v-if="organization.uid && organization.organization_type!=='Bookstore'" name="programs" label="Programs" />
          <q-tab v-if="organization.uid" name="users" label="Users" />
          <q-tab v-if="organization.uid && organization.organization_type!=='Bookstore'" name="courses" label="Courses" />
          <q-tab v-if="organization.uid && organization.organization_type==='Bookstore'" name="purchases" label="Purchases" />
    </q-tabs>

    <q-separator />

    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="details">
        <q-form @submit.prevent="save" class="text-center">
          <ProfilePicUploader v-model="organization.logo"></ProfilePicUploader>
          <q-input
              :disable="!userStore.check_roles(['Superuser', 'Support'])"
              class="q-pa-md"
              :label="organization.organization_type==='Bookstore' ? 'Bookstore Name' : 'Organization Name'"
              v-model="organization.name"
              outlined
              :rules="[val => !!val || 'Field is required']"
          ></q-input>
          <q-select
              :disable="!userStore.check_roles(['Superuser', 'Support']) || !is_new"
              class="q-pa-md"
              label="Region"
              v-model="organization.region"
              :options="regions"
              outlined
              :rules="[val => !!val || 'Field is required']"
          ></q-select>
          <q-input :disable="!userStore.check_roles(['Superuser', 'Support'])" class="q-pa-md" label="Subdomain" v-model="organization.subdomain" outlined></q-input>
          <div v-if="organization.subdomain" class="q-pa-md text-left">
            <q-checkbox v-model="organization.use_saml" label="Use SAML"></q-checkbox><br>
          </div>
          <q-input :disable="!userStore.check_roles(['Superuser', 'Support'])" class="q-pa-md" label="Short Name" v-model="organization.short_name" outlined></q-input>
          <q-input :disable="!userStore.check_roles(['Superuser', 'Support'])" class="q-pa-md" label="Organization Email Domain" v-model="organization.org_domain" outlined></q-input>
          <q-select :disable="!userStore.check_roles(['Superuser', 'Support'])" v-if="organization.organization_type!=='Bookstore'" class="q-pa-md" label="Billing Level" v-model="organization.billing_level" outlined :options="billing_level_options"></q-select>
          <q-select
              v-if="organization.organization_type==='Bookstore'"
              class="q-pa-md"
              label="Organization for Bookstore"
              v-model="organization.bookstore_organization_embed"
              outlined
              use-input
              hide-selected
              fill-input
              input-debounce="300"
              option-label="name"
              option-value="uid"
              :options="options"
              @filter="filterFn"
              @filter-abort="abortFilterFn"
              :disable="!userStore.check_roles(['Superuser', 'Support'])"
          ></q-select>
          <q-btn v-if="userStore.check_roles(['Superuser', 'Support'])" class="q-ma-md" color="negative" @click="confirm_delete=true">Delete</q-btn>
          <q-btn class="q-ma-md" color="secondary" type="submit">Save</q-btn>
        </q-form>
      </q-tab-panel>
      <q-tab-panel name="saml">
        <q-form @submit.prevent="save" class="text-center">
          <div class="text-h5">
            Service Provider Details<br>
            <span class="text-caption">Copy these values into the fields on your identity provider</span>
          </div>
          <q-input
              class="q-pa-md"
              label="SAML ACS URL"
              v-model="acs_url"
              readonly
              outlined
          ></q-input>
          <q-input
              class="q-pa-md"
              label="SAML Entity ID"
              v-model="entity_id"
              outlined
              readonly
          ></q-input>
          <q-input
              class="q-pa-md"
              label="Start URL"
              v-model="organization.saml_entity_id"
              outlined
          ></q-input>
          <div class="text-h5">
            Identity Provider Details<br>
            <span class="text-caption">These Values are provided by your identity provider</span>
          </div>
          <q-input
              class="q-pa-md"
              label="SAML Metadata URL"
              v-model="organization.saml_config.saml_metadata_url"
              @blur="parse_xml"
              outlined
          ></q-input>
          <q-input
              class="q-pa-md"
              label="Idp Entity ID"
              v-model="organization.saml_config.entity_id"
              outlined
          ></q-input>
          <q-input
              class="q-pa-md"
              label="SAML SSO URL"
              v-model="organization.saml_config.sso_url"
              outlined
              :rules="[val => !!val || 'Field is required']"
          ></q-input>
          <q-input
              class="q-pa-md"
              label="SAML SLO URL"
              v-model="organization.saml_config.slo_url"
              outlined
          ></q-input>
          <q-input
              class="q-pa-md"
              label="SAML X509 Certificate"
              v-model="organization.saml_config.saml_x509_certificate"
              outlined
              autogrow
              :rules="[val => !!val || 'Field is required']"
          ></q-input>
          <q-input
              class="q-pa-md"
              label="SHA-256 Fingerprint"
              v-model="organization.saml_config.fingerprint"
              outlined
          ></q-input>
          <q-btn class="q-ma-md" color="secondary" type="submit">Save</q-btn>
        </q-form>
      </q-tab-panel>
      <q-tab-panel name="licenses">
        <LicensePolicy entity_type="organization" :entity_uid="organization_uid" :entity="organization"></LicensePolicy>
      </q-tab-panel>
      <q-tab-panel name="campuses">
        <Campuses></Campuses>
      </q-tab-panel>
      <q-tab-panel name="programs">
        <Programs></Programs>
      </q-tab-panel>
      <q-tab-panel name="users">
        <UsersView :organization_uid="organization_uid"></UsersView>
      </q-tab-panel>
      <q-tab-panel name="courses">
        <Courses :organization_uid="organization_uid"></Courses>
      </q-tab-panel>
      <q-tab-panel name="courses">
        <Courses :organization_uid="organization_uid"></Courses>
      </q-tab-panel>
      <q-tab-panel name="purchases">
        <Purchases :organization_uid="organization_uid"></Purchases>
      </q-tab-panel>
    </q-tab-panels>
  </q-card>
  <q-inner-loading
      :showing="loading"
      label="Please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />
  <q-dialog v-model="confirm_delete">
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar icon="delete" color="negative" text-color="white" />
        <span class="q-ml-sm">Are you sure you want to delete the organization: {{ organization.name }}?</span><br>
        <span>This will delete all Campuses, Programs, and Courses within this school.</span>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="primary" v-close-popup />
        <q-btn label="Delete" color="negative" v-close-popup @click="regionStore.DeleteOrganization(organization); $router.replace(`/organization`)" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, reactive, onMounted, nextTick, watch, computed } from 'vue'
import { useUserStore } from "../stores/user";
import { useRegionStore} from "../stores/region";
import { storeToRefs } from 'pinia'
import Campuses from "../components/Campuses.vue";
import Programs from "../components/Programs.vue";
import UsersView from "./UsersView.vue";
import Courses from "./Courses.vue";
import ProfilePicUploader from "../components/ProfilePicUploader.vue";
import {useRouter} from "vue-router";
import LicensePolicy from "@/components/LicensePolicy.vue";
import Purchases from "@/components/Purchases.vue";

export default {
  name: "OrganizationView",
  components: {LicensePolicy, ProfilePicUploader, Courses, UsersView, Campuses, Programs, Purchases},
  props:['is_new', 'organization_uid', 'bookstore'],
  setup(props){
    const router = useRouter();
    const userStore = useUserStore();
    const regionStore = useRegionStore();
    const { org } = storeToRefs(regionStore);
    const loading = ref(false);
    const confirm_delete = ref(false);
    const options = ref([]);

    const billing_level_options = computed(() => {
      let o = ['Organization', 'Program', 'Campus']
      if(org.value.billing_level === 'Free' || userStore.check_roles(['Superuser', 'Support'])){
        o.push('Free')
      }
      return o;
    })

    onMounted(() => {
      if(props.is_new){
        org.value = {
          name: '',
          region: '',
          subdomain: '',
          short_name: '',
          org_domain: '',
          use_saml: false,
          saml_config: {},
          organization_type: props.bookstore ? 'Bookstore' : 'School',
          billing_level: 'Organization',
        }
      }
      else{
        regionStore.GetOrganization(props.organization_uid);
      }
    });

    return {
      acs_url: `${import.meta.env.VITE_API_URL}/${props.organization_uid}/saml/acs`,
      entity_id: 'https://chartflow.io/sp',
      userStore,
      router,
      tab: ref('details'),
      organization: org,
      regions: import.meta.env.VITE_REGIONS.split(','),
      loading: loading,
      save(){
        loading.value = true;
        regionStore.UpdateOrganization(org.value).then(()=>{
          loading.value = false;
        });
      },
      confirm_delete,
      regionStore,
      options,
      billing_level_options,
      filterFn (val, update, abort) {


        userStore.GetOrganizations(1, 100, 'name', false, val).then((data) => {
          options.value = data.organizations
          update()
        })
      },

      abortFilterFn () {
        // console.log('delayed filter aborted')
      },
      parse_xml(){
        if(org.value.saml_config.saml_metadata_url) {
          loading.value = true;
          regionStore.ParseSAML(org.value.saml_config.saml_metadata_url).then((data) => {
            console.log(data);
            org.value.saml_config.entity_id = data.idp_data.idp.entityId;
            org.value.saml_config.sso_url = data.idp_data.idp.singleSignOnService.url;
            org.value.saml_config.slo_url = data.idp_data.idp.singleLogoutService.url;
            org.value.saml_config.saml_x509_certificate = data.idp_data.idp.x509cert;
            loading.value = false;
          });
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
