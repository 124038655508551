<template>
  <q-card class="col-4 bg-secondary ">
    <q-card-section class="bg-primary text-white row" style="border-radius: 6px;">
      <div class="col">
        <div class="row">
          <div class="col-shrink">
            <q-avatar color="white" class="text-primary">
              <img v-if="patient.patient_picture" :src="patient.patient_picture" alt="Patient Picture" />
              <span v-else>{{ initials }}</span>
            </q-avatar>
          </div>
          <div class="col-9 q-ml-md vertical-middle"><span class="text-bold">{{ patient.name }}</span><br>
            <span class="text-bold">Age: </span>{{ patient.age}} | <span class="text-bold">DOB: </span>{{ date_string(patient, patient.date_of_birth) }} | <span class="text-bold">Sex: </span>{{ patient.sex }}<br>
            <span class="text-bold">MRN: </span>{{ patient.mrn}}
          </div>
        </div>
      </div>
      <div class="col-3 text-right">
        <q-btn v-if="add !== undefined" class="q-ml-md q-mb-md" icon="person_add_alt_1" glossy color="secondary" @click="$emit('add', patient)">&nbsp;Add</q-btn>
        <q-btn v-if="preview !== undefined" class="q-ml-md q-mb-md" outline color="white" target="_blank" :href="`/patient/${patient.uid}`">Preview</q-btn>
        <q-btn v-else outline color="white" class="q-ml-md q-mb-md" target="_blank" :href="`/patient/${patient.uid}`">Patient Chart</q-btn>
        <q-btn v-if="remove !== undefined" color="negative" class="q-ml-md" @click="$emit('remove')">Remove</q-btn>
      </div>
    </q-card-section>
    <q-card-section class="text-white q-pa-sm">
        <q-btn
          color="grey"
          round
          flat
          dense
          :icon="expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
          @click.prevent.capture.stop="expanded = !expanded"
        />
        <q-slide-transition>
          <div v-show="expanded">
            <q-separator />
            <q-card-section class="text-subitle2" v-html="patient.patient_description">
            </q-card-section>
          </div>
        </q-slide-transition>
    </q-card-section>

  </q-card>
</template>

<script>
import { date_string } from "../util";
import {ref} from 'vue';

export default {
  props: ['patient', 'remove', 'preview', 'add'],
  emits: ['remove'],
  name: "PatientBox",
  setup(){
    return {
      expanded: ref(false),
      date_string
    }
  },
  computed: {
    initials() {
      if(this.patient && this.patient.name) return this.patient.name.split(' ').map(name => name[0]).join('');
    }
  },

}
</script>

<style scoped>

</style>
