<template>
    <div class="row q-pa-md">
      <div class="col-6 text-h5">
        {{ flowsheet.flowsheet_name }}
        <q-fab class="absolute" style="right: 10px; top: -10px;" v-if="!editing && (new_mode || edit_mode!==null)" color="primary" flat icon="close" @click="new_mode=false; edit_mode=null;"></q-fab>
        <q-btn class="absolute-top-right q-ma-md" v-if="!new_mode && !edit_mode && !readonly" color="primary" icon="add" @click="new_entry()">New Entry</q-btn>
      </div>
    </div>
      <q-markup-table separator="vertical" ref="table" wrap-cells>
        <thead>
          <draggable v-model="flowsheet.fields" item-key="uid" group="fields" :disabled="!editing" tag="tr">
            <template #item="{element}">
              <th :class="{ 'active-row': active_field ? element.uid === active_field.uid : false}" @click="$emit('selected', element)" scope="col">
                {{ element.field_name }}
              </th>
            </template>
            <template #header>
              <th>&nbsp;</th>
            </template>
          </draggable>
        </thead>
        <tbody>
          <tr v-for="d in data.times">
            <td v-if="edit_mode === d" style="min-width: 300px; max-width: 500px;">
                <PatientDateTimeField dense bgColor="grey-4" class="q-ma-xs" :patient="patientStore.patient" v-model="d.timestamp"></PatientDateTimeField>
            </td>
            <td v-else>
                <q-avatar size="36px" color="secondary">
                  <img v-if="d.user_embed.picture" :src="d.user_embed.picture"/>
                  <span v-else>{{ d.user_embed.initials }}</span>
                  <q-tooltip class="bg-secondary text-white">
                    {{ d.user_embed.username }}
                  </q-tooltip>
                </q-avatar>
                <div class="q-ml-sm vertical-middle inline-block text-center">
                  {{ date_string(patientStore.patient, d.timestamp) }}<br><span v-if="!['template', 'scene'].includes(patientStore.patient.patient_type)">{{ time_string(patientStore.patient, d.timestamp) }}</span>

                </div>
                <q-btn v-if="!new_mode && !edit_mode && !readonly && !d.read_only" color="grey-4" round size="sm" class="q-ma-sm" @click="edit(d)">
                  <q-icon class="text-primary" name="edit"></q-icon>
                </q-btn>
                <q-btn v-if="['template', 'scene'].includes(patientStore.patient.patient_type) && !new_mode && !edit_mode && !readonly && can_edit(d.user_embed.uid) && !d.read_only" color="negative" round size="sm" class="q-ma-sm" @click="delete_data(d)">
                  <q-icon class="text-white" name="close"></q-icon>
                </q-btn>
            </td>
            <td v-for="element in flowsheet.fields">
              <component :edit="edit_mode === d" outlined dense :is="lookup(element.data_type)" v-model="d[element.uid]" :patient="patientStore.patient" :field="element"></component>
              <PatientHelp :element="element"></PatientHelp>
            </td>
          </tr>
          <tr v-if="new_mode" ref="new_ele">
            <td>
              <PatientDateTimeField dense bgColor="grey-4" class="q-ma-xs" :patient="patientStore.patient" v-model="newcolumn.timestamp"></PatientDateTimeField>
            </td>
            <td v-for="element in flowsheet.fields">
              <component :edit="true" outlined dense :is="lookup(element.data_type)" v-model="newcolumn[element.uid]" :patient="patientStore.patient" :field="element"></component>
              <PatientHelp :element="element"></PatientHelp>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
    <q-page-sticky class="q-ma-md">
      <q-fab v-if="!editing && (new_mode || edit_mode!==null)" color="secondary" label="Save" icon="save" @click="save"></q-fab>
    </q-page-sticky>
    <q-dialog v-model="confirm_delete" persistent>
      <q-card>
      <q-card-section>
        Are you sure you want to delete the date: {{ date_time_string(patientStore.patient, delete_holder.timestamp) }}
      </q-card-section>
      <q-card-actions>
        <q-btn class="q-ma-sm" @click="confirm_delete=false">Cancel</q-btn>
        <q-btn class="q-ma-sm" color="negative" @click="delete_confirmed()">Delete</q-btn>
      </q-card-actions>
      </q-card>
    </q-dialog>

</template>

<script>
import draggable from 'vuedraggable'
import { epoch_offset_to_datetime, date_time_string, datetime_to_epoch_offset, date_string, time_string} from "../../util";
import NumericField from "./NumericField.vue";
import PatientDateTimeField from "../PatientDateTimeField.vue";
import {nextTick, ref} from "vue";
import AdvancedForm from "./AdvancedForm.vue";
import {usePatientData} from "../../composables/patient_data";
import PatientHelp from "./PatientHelp.vue";

export default {
  name: "Table",
  props: ["flowsheet", "editing", "active_field", "data", "new_mode"],
  components: {
    PatientHelp,
    AdvancedForm,
    PatientDateTimeField,
    draggable,
    NumericField,
  },
  setup(props) {
    const {
        advanced_add,
        newcolumn,
        advanced_obj,
        advanced_element,
        new_ele,
        new_mode,
        edit_mode,
        patientStore,
        userStore,
        viewing_visit,
        new_entry,
        readonly,
        save,
        edit,
        start_advanced_add,
        finish_advanced_add,
        select_action,
        lookup,
        can_edit,
        delete_data,
        confirm_delete,
        delete_holder,
        delete_confirmed,
        reset_data
    } = usePatientData(props);


    return {
      readonly,
      viewing_visit,
      patientStore,
      userStore,
      newcolumn,
      new_mode,
      edit_mode,
      new_entry,
      new_ele,
      date_string,
      time_string,
      datetime_to_epoch_offset,
      lookup,
      save,
      edit,
      can_edit,
      delete_data,
      confirm_delete,
      delete_holder,
      delete_confirmed,
      date_time_string,
      reset_data,
    }
  }
}
</script>

<style scoped>

</style>
