<template>
  <draggable v-model="field_data" item-key="uid" group="fields" tag="div">
    <template #item="{element}">
      <div :class="{ 'active-row': selected_field ? element.uid === selected_field.uid : false}" @click="$emit('selected', element)">
        {{ element.field_name }}
        <component :edit="true" outlined dense :is="lookup(element.data_type)" v-model="data[element.uid]" :patient="patientStore.patient" :field="element" :readonly="readonly"></component>
        <q-menu no-focus v-if="element.help" anchor="center right" self="center left">
          <q-card>
            <q-card-section class="bg-secondary text-h6 q-pa-sm text-white">
              Helpful Information
            </q-card-section>
            <q-card-section>
              <div v-html="element.help"></div>
            </q-card-section>
          </q-card>
        </q-menu>
      </div>
    </template>
  </draggable>
</template>

<script>
import {onMounted, ref, watch, toRef} from 'vue'
import draggable from 'vuedraggable'
import { usePatientStore } from "../../stores/patient";
import { useUserStore } from "../../stores/user";
import { lookup } from "../../composables/patient_data";

export default {
  name: "AdvancedForm",
  props: ["fields", "data", "selected_field", "readonly"],
  emits: ["update:fields", "selected"],
  components: {
    draggable,
  },
  setup(props, {emit}) {
    const patientStore = usePatientStore();
    const userStore = useUserStore();
    const field_data = ref([]);
    const data = toRef(props, "data");

    onMounted(() => {
      field_data.value = props.fields;
    });

    watch(field_data, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        console.log('Updated')
        emit('update:fields', newVal)
      }
    })

    watch(() => props.fields, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        field_data.value = newVal;
      }
    })

    return {
      data,
      field_data,
      patientStore,
      userStore,
      lookup
    };
  },
}
</script>

<style scoped>

</style>
