<template>
  <q-table
      title="Users"
      class="q-pa-md"
      style="height: calc(100vh - 120px);"
      :columns="columns" :rows="users" row-key="uid"
        separator="vertical" :loading="loading" :filter="filter"
      v-model:pagination="pagination"
      @request="onRequest"
      :grid="$q.screen.xs"
      :visible-columns="visibleColumns"
      :rows-per-page-options="[10,20,50,100]"
      flat
      >
    <template v-slot:top-left>
      <div>
        <div class="text-h5 text-primary q-mb-md">Users</div>
        <div>
          <q-input outlined dense debounce="300" v-model="filter" placeholder="Search">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </div>
      </div>
    </template>
    <template v-slot:top-right>
      <q-btn-dropdown v-if="roles_list" color="primary" label="Add Users" class="q-mr-md">
        <q-list>
          <template v-for="role in roles_list">
            <q-item clickable v-close-popup @click="select_users(role)">
              <q-item-section>
                <q-item-label>{{ role }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-list>
      </q-btn-dropdown>
      <q-btn-dropdown v-if="roles_list" label="Upload Users">
        <q-list>
          <template v-for="role in roles_list">
            <q-item clickable v-close-popup @click="upload_window=true; upload_role=role">
              <q-item-section>
                <q-item-label>{{ role }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-list>
      </q-btn-dropdown>
      <q-dialog v-model="upload_window">
        <q-card class="text-center q-pa-md">
          <h5>Upload Users</h5>
          <h4>Drop CSV or Excel files to upload<br/>or</h4>
          <label class="q-btn bg-secondary text-white cursor-pointer rounded-borders" for="file">Select File</label>
          <file-upload
          :extensions="extensions"
          :accept="accept"
          :multiple="false"
          :directory="false"
          :create-directory="false"
          :thread="thread < 1 ? 1 : (thread > 5 ? 5 : thread)"
          :drop="true"
          :drop-directory="false"
          :add-index="false"
          v-model="files"
          @input-file="inputFile"
          ref="upload">
          </file-upload>
        </q-card>
      </q-dialog>
    </template>
    <template v-slot:body-cell-email="props">
      <q-td>
        <span v-for="email in props.value">{{ email }}<br></span>
      </q-td>
    </template>
    <template v-slot:body-cell-organizations="props">
      <q-td>
        <span v-for="org in props.value"><router-link :to="`/organization/${org.organization_uid}`">{{ org.organization_name }}</router-link><br></span>
      </q-td>
    </template>
    <template v-slot:body-cell-roles="props">
      <q-td style="max-width: 500px;">
        <div style="white-space: break-spaces">
          <q-badge class="q-ma-xs" v-for="role in props.row.roles" color="primary">{{ role }}</q-badge>
          <q-badge class="q-ma-xs" v-for="role in calc_roles(props.row)" color="primary">{{ role }}</q-badge>
        </div>
      </q-td>
    </template>
    <template v-slot:body-cell-license="props">
      <q-td>
          <span>{{ date.formatDate(props.value, 'MM/DD/YYYY') }}</span>
      </q-td>
    </template>
    <template v-slot:body-cell-actions="props">
      <q-td>
        <q-btn
            v-if="userStore.check_roles(['Superuser', 'Support']) && intersection(['Superuser', 'Support'], props.row.roles).length == 0"
            class="q-ma-sm"
            rounded
            color="accent"
            @click="become_user(props.value)"
            size="sm">
          Become User
        </q-btn>
        <q-btn class="q-ma-sm" rounded color="primary" :to="`/user/${props.value}`" size="sm">Manage User</q-btn>
        <q-btn v-if="organization_uid || campus_uid || program_uid || course_uid" class="q-ma-sm" rounded color="negative" @click="delete_user = props.row; confirm_delete=true;" size="sm">Remove User</q-btn>
      </q-td>
    </template>
    <template v-slot:loading>
        <q-inner-loading showing color="primary" />
    </template>
    <template v-slot:item="props">
      <q-card style="width: 100%" class="q-ma-md">
        <q-card-section><UserIcon :user="props.row" color="primary"></UserIcon>&nbsp;{{ props.row.username}}</q-card-section>
        <q-separator></q-separator>
        <q-card-section><span class="text-bold">Email</span><br><span v-for="email in props.colsMap.email.field(props.row)">{{ email }}<br></span></q-card-section>
        <q-card-section><span class="text-bold">Organizations</span><br><span v-for="org in props.row.schools"><router-link :to="`/organization/${org.organization_uid}`">{{ org.organization_name }}</router-link><br></span></q-card-section>
        <q-card-section><span class="text-bold">Roles</span><br>
          <q-badge class="q-ma-xs" v-for="role in props.row.roles" color="primary">{{ role }}</q-badge>
          <q-badge class="q-ma-xs" v-for="role in calc_roles(props.row)" color="primary">{{ role }}</q-badge>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-actions>
          <q-btn
              v-if="userStore.check_roles(['Superuser', 'Support']) && intersection(['Superuser', 'Support'], props.row.roles).length == 0"
              class="q-ma-sm"
              rounded
              color="accent"
              @click="become_user(props.row.uid)"
              size="sm">
            Become User
          </q-btn>
          <q-btn class="q-ma-sm" rounded color="primary" :to="`/user/${props.row.uid}`" size="sm">Manage User</q-btn>
          <q-btn v-if="organization_uid || campus_uid || program_uid || course_uid" class="q-ma-sm" rounded color="negative" @click="delete_user = props.row; confirm_delete=true;" size="sm">Remove User</q-btn>
        </q-card-actions>
      </q-card>
    </template>
  </q-table>
    <q-dialog v-model="confirm_delete">
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="delete" color="negative" text-color="white" />
          <span class="q-ml-sm">Are you sure you want to Remove the User: {{delete_user.username }}?</span><br>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup />
          <q-btn label="Remove" color="negative" v-close-popup @click="remove_user(delete_user.uid)" />
        </q-card-actions>
      </q-card>
    </q-dialog>

</template>

<script>
import {ref, reactive, onMounted, nextTick, watch, computed} from 'vue'
import { useUserStore } from "../stores/user";
import { useRegionStore} from "../stores/region";
import {useQuasar} from "quasar";
import UserSelector from "../components/UserSelector.vue";
import FileUpload from 'vue-upload-component'
import { intersection } from "../util"
import {storeToRefs} from "pinia";
import UserIcon from "@/components/UserIcon.vue";
import {date} from "quasar";

export default {
  name: "UsersView",
  emits: ['add', 'remove'],
  props: ['organization_uid', 'campus_uid', 'program_uid', 'course_uid', 'campus', 'program'],
  components: {
    UserIcon,
    UserSelector,
    FileUpload
  },
  setup(props, ctx){
    const userStore = useUserStore();
    const {user} = storeToRefs(userStore);
    const confirm_delete = ref(false);
    const delete_user = ref(null);
    const regionStore = useRegionStore();
    const users = ref([]);
    const loading = ref(false);
    const filter = ref('');
    const pagination = ref({
        rowsNumber: 10,
        page: 1,
        rowsPerPage: 50,
        sortBy: 'last_name',
        descending: false,
    })

    onMounted(() => {
      onRequest({
        pagination: pagination.value,
        filter: undefined,
        organization_uid: props.organization_uid,
        campus_uid: props.campus_uid,
        program_uid: props.program_uid,
        course_uid: props.course_uid,
      })
    });

    const roles_list = computed(() => {
      return userStore.available_roles(props.organization_uid, props.program_uid, props.campus_uid, props.course_uid);
    });

    function onRequest(p) {
        const { page, rowsPerPage, sortBy, descending } = p.pagination
        const filter = p.filter
        loading.value = true

        userStore.GetUsers(
            props.organization_uid, props.campus_uid, props.program_uid, props.course_uid,
            page,
            rowsPerPage,
            sortBy,
            descending,
            filter).then(data => {

          pagination.value.rowsNumber = data.total
          users.value.splice(0, users.value.length, ...data.users);

          pagination.value.page = page;
          pagination.value.rowsPerPage = rowsPerPage
          pagination.value.sortBy = sortBy
          pagination.value.descending = descending

          loading.value = false
        });
    }

    const $q = useQuasar()

    function get_entity(){
        let entity = {}
        if(props.course_uid){
          entity.entity_type = 'course'
          entity.uid = props.course_uid
        }
        else if (props.program_uid){
          entity.entity_type = 'program'
          entity.uid = props.program_uid
        }
        else if (props.campus_uid){
          entity.entity_type = 'campus'
          entity.uid = props.campus_uid
        }
        else if (props.organization_uid){
          entity.entity_type = 'organization'
          entity.uid = props.organization_uid
        }
        else {
          console.log('No entity selected, Superuser')
        }
        return entity
    }

    function select_users(role) {
      $q.dialog({
        component: UserSelector,

        // props forwarded to your custom component
        componentProps: {
          organization_uid: props.organization_uid,
          role: role,
          campus: props.campus,
          program: props.program,
          // ...more..props...
        }
      }).onOk((new_users) => {
        loading.value = true
        console.log('OK');
        console.log(new_users);
        regionStore.AddOrInviteUsers(new_users, role, get_entity()).then((new_users) => {
          for(let nu of new_users) {
            if(nu.uid === user.value.uid){
              user.value = nu
            }
            ctx.emit('add', {user: nu, role: role})
            let found = false;
            for (let u in users.value) {

              if (users.value[u].uid == nu.uid) {
                users.value[u] = nu
                found = true
                break
              }
            }
            if (!found) {
              users.value.push(nu);
              pagination.value.rowsNumber++;
            }
          }
          loading.value = false
        })
      }).onCancel(() => {
        console.log('Cancel')
      }).onDismiss(() => {
        console.log('Called on OK or Cancel')
      })
    }

    const visibleColumns = ref(['first_name', 'last_name', 'username', 'email', 'roles', 'actions'])
    if(!props.organization_uid) visibleColumns.value.push('organizations');
    if(userStore.check_roles(['Superuser', 'Support'])) visibleColumns.value.push('license');

    return {
      date,
      intersection,
      get_entity,
      files: ref([]),
      accept: 'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      extensions: 'csv,xls,xlsx',
      thread: 3,
      columns: [
        {
          name: 'first_name',
          label: 'First Name',
          field: 'first_name',
          align: 'left',
          sortable: true,
        },
        {
          name: 'last_name',
          label: 'Last Name',
          field: 'last_name',
          align: 'left',
          sortable: true,
        },
        {
          name: 'username',
          label: 'Username',
          field: 'username',
          align: 'left',
          sortable: true,
        },
        {
          name: 'email',
          label: 'Email',
          field: function(row) {
            let emails = [];
            for(let uam in row.uams){
              if(row.uams[uam]["system"] === 'email'){
                emails.push(row.uams[uam]["system_key"]);
              }
            }
            return emails;
          },
          align: 'left',
          sortable: true,
        },
        {
          name: 'organizations',
          label: 'Organizations',
          field: 'schools',

          align: 'left',
          sortable: true,
        },
        {
          name: 'roles',
          label: 'Roles',
          field: 'schools',
          align: 'left',
          sortable: false,
        },
        {
          name: 'license',
          label: 'License End',
          field: function(row) {
            let end_date = null;
            for(let license in row.licenses){
              if(Date.parse(row.licenses[license].end_date) > end_date || end_date === null){
                end_date = Date.parse(row.licenses[license].end_date);
              }
            }
            return end_date
          },
          align: 'left',
          sortable: false,
        },
        {
          name: 'actions',
          label: 'Actions',
          field: 'uid',
          align: 'left',
          sortable: false,
        },
      ],
      visibleColumns,
      user,
      users,
      confirm_delete,
      delete_user,
      loading,
      userStore,
      regionStore,
      filter,
      pagination,
      upload_window: ref(false),
      upload_role: ref(null),
      onRequest,
      select_users,
      calc_roles(user){
        const school = user.schools.find(school => school.organization_uid === props.organization_uid);
        let program = null;
        let course = null;
        let campus = null;
        if(school) {
          program = school.programs.find(program => program.program_uid === props.program_uid);
          campus = school.campuses.find(campus => campus.campus_uid === props.campus_uid);
          if(school.courses) {
            course = school.courses.find(course => course.uid === props.course_uid);
          }
        }
        if(props.course_uid) return course.roles;
        if(props.program_uid) return program.roles;
        if(props.campus_uid) return campus.roles;
        if(props.organization_uid) return school.roles;
        if(!props.organization_uid){
          const roles = [];
          for(let school in user.schools){
            roles.push(...user.schools[school].roles);
          }
          return [...new Set(roles)];
        };
      },
      roles_list
    }
  },
  methods:{
    become_user(uid){
      this.loading = true;
      this.userStore.BecomeUser(uid).then((success) => {
        if(success){
          this.$router.push(this.userStore.default_route);
        }
        this.loading = false;
      });
    },
    remove_user(uid){
      this.loading = true;
      this.regionStore.RemoveUser(uid, this.get_entity()).then((success) => {
        if(success){
          this.users = this.users.filter(user => user.uid !== uid);
          this.$emit('remove', uid);
          this.pagination.rowsNumber--;
        }
        this.loading = false;
        this.delete_user = null;
        this.confirm_delete = false;
      });
    },
    inputFile: function (newFile, oldFile) {
      let self = this;
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // Get response data
        console.log('response', newFile.response);
        if (newFile.xhr) {
          //  Get the response status code
          console.log('status', newFile.xhr.status)
          if (newFile.xhr.status > 399) {
              alert('Error uploading file');
              this.loading = false;
          }
        }
      }
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
          console.log("File Added");
          this.loading = true;
          let key = newFile.name
          this.userStore.GetUploadUrl(key, newFile.type)
          .then(response => {
            this.$refs.upload.update(newFile.id, {
              active: true,
              putAction: response.url,
              download_url: response.download_url,
              //headers: { 'x-amz-acl': 'public-read' }
            });
            //newFile.download_url = response.download_url;
            //newFile.putAction = response.url
            //if (!this.$refs.upload.active) {
            //  this.$refs.upload.active = true;
            //}
          });
      }
      if (newFile && oldFile && newFile.success !== oldFile.success) {
          console.log('success', newFile.success, newFile)
          const entity = this.get_entity()
          this.$router.push(`/handle_upload?url=${newFile.download_url}&entity_type=${entity.entity_type}&entity_uid=${entity.uid}&role=${this.upload_role}`);
          this.loading = false;
      }
    },
  }
}
</script>

<style scoped>

</style>
