<template>
  <template v-if="!edit">
    <div v-if="field.list_type==='Multiple' || field.list_type==='Checkbox'">
      <q-chip v-for="item in modelValue">{{ item }}</q-chip>
    </div>
    <span v-else>{{ modelValue }}</span>
  </template>
  <template v-else>
    <q-select v-if="field.list_type==='Single'" :disable="readonly" outlined :options="field.field_options" :model-value="modelValue" @update:model-value="$emit('update:modelValue', $event)"></q-select>
    <q-select v-else-if="field.list_type==='Multiple'" :disable="readonly" outlined multiple use-chips :options="field.field_options" :model-value="modelValue" @update:model-value="$emit('update:modelValue', $event)"></q-select>
    <q-option-group v-else :disable="readonly" :options="options" type="checkbox" v-model="data"></q-option-group>
  </template>
</template>

<script>
import {onMounted, ref} from "vue";

export default {
  emits: ['update:modelValue'],
  props: ['field', 'modelValue', 'edit', 'outlined', 'dense', 'patient', 'readonly'],
  name: "SelectField",
  setup(props, context){
    const data = ref(null);
    if ((props.modelValue === undefined || props.modelValue === null) && (props.field.list_type === 'Multiple' || props.field.list_type === 'Checkbox')) {
      data.value = [];
    }

    onMounted(() => {
      if ((props.modelValue === undefined || props.modelValue === null) && (props.field.list_type === 'Multiple' || props.field.list_type === 'Checkbox')) {
        data.value = [];
      }
      else {
        data.value = props.modelValue;
      }
      if ((props.modelValue === undefined || props.modelValue === null) && (props.field.list_type === 'Multiple' || props.field.list_type === 'Checkbox')) {
        context.emit('update:modelValue', null)
      }
      if (typeof props.modelValue === 'string' && (props.field.list_type === 'Multiple' || props.field.list_type === 'Checkbox')) {
        context.emit('update:modelValue', [props.modelValue])
      }
    })

    return {
      data
    }
  },
  computed: {
    options: function options() {
      return this.field.field_options.map(function (option) {
        return {
          label: option,
          value: option
        };
      });
    }
  },
  watch: {
    data: function data(newVal) {
      if(newVal === null || newVal === undefined || newVal.length === 0) {
        this.$emit('update:modelValue', null)
      }
      else {
        this.$emit('update:modelValue', newVal)
      }
    }
  }
}
</script>

<style scoped>

</style>
