<template>
  <q-drawer side="right" :model-value="true">
    <div v-if="modelValue">
      <div class="q-pa-md bg-primary text-white text-center text-h5">Field Settings</div>
      <q-input class="q-ma-md" v-model="modelValue.field_name" outlined label="Field Name"></q-input>
      <q-select class="q-ma-md" v-model="modelValue.data_type" outlined label="Field Type" :options="['Text', 'Number', 'Date/Time', 'Date', 'Time', 'List Select', 'Multiline Text', 'File', 'Advanced', 'Lookup', 'Lookup Advanced', 'Multiple Entries', 'Frequency', 'Schedule', 'Barcode', 'Flowsheet']"></q-select>
      <q-select
          v-if="['Lookup', 'Lookup Advanced'].includes(modelValue.data_type)"
          class="q-ma-md"
          v-model="modelValue.lookup_type"
          outlined
          label="Lookup Types"
          :options="lookup_types"
          option-label="field_name"
          option-value="uid"
          emit-value
          map-options
          multiple
          input-debounce="650"
          :loading="loading"
          @remove="remove_lookup_type"
          @add="add_lookup_type"
          @virtual-scroll="onScroll"
      >
        <template v-slot:after>
          <q-btn round dense flat icon="add" @click="lookup_type_name='';prompt=true"/>
        </template>
        <template v-slot:selected-item="scope">
          <q-chip
            removable
            dense
            clickable
            @remove="scope.removeAtIndex(scope.index)"
            @click.prevent.stop="$router.push(`/lookups/${flowsheet.uid}/${scope.opt.uid}?version=${version}`)"
            :tabindex="scope.tabindex"
            class="q-ma-none"
          >
            {{ scope.opt.field_name }}
          </q-chip>
        </template>
      </q-select>
      <q-select v-if="modelValue.data_type === 'Lookup'" class="q-ma-md" v-model="modelValue.list_type" outlined label="List Type" :options="['Single', 'Multiple']"></q-select>
      <div v-if="modelValue.data_type === 'Number'">
        <span class="q-ma-md">Units</span>
        <q-input v-for="(unit, index) in modelValue.field_units" v-model="modelValue.field_units[index]" class="q-ma-md" outlined label="Unit">
          <template v-slot:after>
            <q-btn icon="close" flat color="negative" @click="modelValue.field_units.splice(index, 1)"></q-btn>
          </template>
        </q-input>
        <q-btn class="q-ma-md" icon="add" @click="add_unit">Add Unit</q-btn>
      </div>
      <div v-if="modelValue.data_type === 'List Select'">
        <q-select class="q-ma-md" v-model="modelValue.list_type" outlined label="List Type" :options="['Single', 'Multiple', 'Checkbox']"></q-select>
        <span class="q-ma-md">Options</span>
        <q-input v-for="(option, index) in modelValue.field_options" v-model="modelValue.field_options[index]" class="q-ma-md" outlined label="Option">
          <template v-slot:after>
            <q-btn icon="close" flat color="negative" @click="modelValue.field_options.splice(index, 1)"></q-btn>
          </template>
        </q-input>
        <q-btn class="q-ma-md" icon="add" @click="add_option">Add Option</q-btn>
      </div>
      <div v-if="modelValue.data_type === 'Multiple Entries'">
        <q-btn class="q-ma-md" icon="edit" :to="`/flowsheet_builder/${version}/${flowsheet.uid}/multiple/${modelValue.uid}`">Edit</q-btn>
      </div>
      <div v-if="modelValue.data_type === 'Advanced'">
        <q-btn class="q-ma-md" icon="edit" :to="`/flowsheet_builder/${version}/${flowsheet.uid}/advanced/${modelValue.uid}`">Edit</q-btn>
      </div>
      <div v-if="modelValue.data_type === 'Advanced'">
        <span class="q-ma-md">Actions</span>
        <q-input v-for="(action, index) in modelValue.actions" v-model="modelValue.actions[index].action_name" class="q-ma-md" outlined label="Action">
          <template v-slot:after>
            <q-btn icon="close" round flat color="negative" @click="modelValue.actions.splice(index, 1)"></q-btn>
            <q-btn class="q-ma-md" round flat icon="edit" @click="edit_action(action)"></q-btn>
          </template>
        </q-input>
        <q-btn class="q-ma-md" icon="add" @click="add_action">Add Action</q-btn>
      </div>
      <span class="q-ml-md">Help Text:</span>
      <q-editor class="q-ma-md" v-model="modelValue.help"></q-editor>
      <q-btn class="q-ma-md" color="negative" @click="$emit('remove')">Remove</q-btn>
    </div>
  </q-drawer>
  <q-dialog v-model="prompt" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <q-btn class="absolute-top-right" icon="close" flat round dense v-close-popup></q-btn>
        <div class="text-h6">New Lookup Type Name</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <q-input dense v-model="lookup_type_name" outlined autofocus @keyup.enter="add_new_lookup()" />
      </q-card-section>

      <q-card-actions align="right" class="text-primary">
        <q-btn flat label="Add Lookup Type" @click="add_new_lookup()"/>
      </q-card-actions>
    </q-card>
  </q-dialog>

</template>

<script>
import {onMounted, ref} from "vue";
import {useFlowsheetStore} from "../stores/flowsheet";
import {generate} from "xksuid";

export default {
  props: ['modelValue', 'flowsheet', 'version'],
  emits: ['update:modelValue', 'remove'],
  name: "FieldDrawer",
  setup(props){
    const lookup_type_name = ref('');
    const lookup_types = ref([]);
    const flowsheets = ref([]);
    const flowsheetStore = useFlowsheetStore();
    const prompt = ref(false);
    const loading = ref(false);
    const nextPage = ref(2);
    const morePages = ref(true)

    onMounted(() => {
      flowsheetStore.GetLookupTypesForVersion(null, null, null, null, null, props.version).then(data => {
        lookup_types.value = data.lookup_types;
        nextPage.value = 2;
        if (data.lookup_types.length < 50) {
          morePages.value = false
        }
      });
    });

    return {
      loading,
      flowsheets,
      lookup_type_name,
      lookup_types,
      prompt,
      onScroll ({ to, ref }) {
        const lastIndex = lookup_types.value.length - 1

        if (loading.value !== true && to === lastIndex && morePages.value) {
          loading.value = true

          flowsheetStore.GetLookupTypesForVersion(nextPage.value, null, null, null, null, props.version).then(data => {
            nextPage.value++;
            if (data.lookup_types.length <= 12) {
              morePages.value = false
            }
            lookup_types.value.push(...data.lookup_types);
            loading.value = false
          });
        }
      }
    }
  },
  methods:{
    add_unit(){
      if(this.modelValue.field_units === null || this.modelValue.field_units === undefined){
        this.modelValue.field_units = [];
      }
      this.modelValue.field_units.push('');
    },
    add_option(){
      if(this.modelValue.field_options === null || this.modelValue.field_options === undefined){
        this.modelValue.field_options = [];
      }
      this.modelValue.field_options.push('');
    },
    add_action(){
      if(!this.modelValue.actions) this.modelValue.actions = [];
      this.modelValue.actions.push({action_name: ''});
    },
    edit_action(action){
      const field_uid = this.modelValue.uid;
      this.$emit('update:modelValue', action);
      this.$router.push(`/flowsheet_builder/${this.version}/${this.flowsheet.uid}/advanced/${field_uid}/action/${action.uid}`)
    },
    remove_lookup_type(lookup_type){
      this.modelValue.lookup_type.splice(this.modelValue.lookup_type.indexOf(lookup_type.value), 1);
      this.flowsheet.lookup_data[lookup_type.value].using.splice(this.flowsheet.lookup_data[lookup_type.value].using.indexOf(this.modelValue.uid), 1);
      if(this.flowsheet.lookup_data[lookup_type.value].using.length === 0){
        delete this.flowsheet.lookup_data[lookup_type.value];
      }
    },
    add_lookup_type(lookup_type){
      if(!this.modelValue.lookup_type){
        this.modelValue.lookup_type= [];
      }
      let lookup_value = this.lookup_types.find(l => l.uid === lookup_type.value)
      this.modelValue.lookup_type.push(lookup_type.value);
      let check_lookup_data = this.flowsheet.lookup_data[lookup_type.value];
      if(!check_lookup_data){
        lookup_value.using = [];
        this.flowsheet.lookup_data[lookup_type.value] = lookup_value;
        for(let lt of lookup_value.lookup_type){
          let lv = this.lookup_types.find(l => l.uid === lt)
          let cldata = this.flowsheet.lookup_data[lt];
          if(!cldata) {
            lv.using = [];
            this.flowsheet.lookup_data[lt] = lv;
          }
          lv.using.push(this.modelValue.uid);
        }
      }
      lookup_value.using.push(this.modelValue.uid);
    },
    add_new_lookup(){
      this.prompt = false

      const lookup_type = {
        uid: generate(),
        field_name: this.lookup_type_name,
        flowsheet_version: this.version,
        using: [this.modelValue.uid]
      }
      this.lookup_types.push(lookup_type);

      this.flowsheet.lookup_data[lookup_type.uid] = lookup_type

      if(!this.modelValue.lookup_type){
        this.modelValue.lookup_type= [];
      }
      if(!this.modelValue.lookup_type.push){
        this.modelValue.lookup_type = [this.modelValue.lookup_type];
      }
      this.modelValue.lookup_type.push(lookup_type.uid);

      this.lookup_type_name = '';
    },

  }
}
</script>

<style scoped>

</style>