<template>
    <div class="q-col-gutter-md row items-start">
      <q-input class="col-8 q-pt-none q-pl-none" :bg-color="bgColor" outlined :dense="dense" :label="`${clabel}Date`" v-model="field_date" @blur="handle_blur($event)">
        <template v-slot:append>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy v-model="visible" persistent no-focus ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
              <q-date v-model="field_date" today-btn mask="DD MMM YYYY">
                <div class="row items-center justify-end">
                  <q-btn @click="close_date()" label="Close" color="primary" flat />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>

      </q-input>
      <q-input ref="timefieldref" :rules="[val => !val || !!field_date || 'Time must have a date']" class="col-4 q-pt-none" outlined :bg-color="bgColor" :dense="dense" :label="`${clabel}Time`" mask="##:##" v-model="field_time" @blur="handle_blur($event)">
      </q-input>
    </div>
</template>

<script>
import { ref } from 'vue'
import { date } from 'quasar'
import {datetimedmy_to_epoch_offset} from "../util";

export default {
  name: "DateTimeField",
  props: ['label','dense', 'bgColor', 'modelValue'],
  emits: ['update:modelValue'],
  setup(props){
    const timefieldref = ref(null)
    const field_date = ref(null);
    const field_time = ref(null);
    if(props.modelValue){
      field_date.value = date.formatDate(props.modelValue, 'DD MMM YYYY');
      field_time.value = date.formatDate(props.modelValue, 'HH:mm');
    }
    return {
      timefieldref,
      field_date,
      field_time,
      visible: ref(false),
      closing: ref(false),
      open_from_focus: ref(false),
    }
  },
  computed: {
    clabel(){
      return this.label ? this.label + ' ' : ''
    }
  },
  methods:{
    handle_focus(e){
      if(this.closing){
        this.closing = false;
        this.open_from_focus = true;
        return;
      }
      this.visible = true
    },
    handle_blur(e){
      if(this.field_date) {
        let d = ''
        //const d = this.field_date + ' ' + (this.field_time ? this.field_time : '00:00');
        const parts = this.field_date.split(' ');
        d = parts[0] + ' ' + parts[1].charAt(0).toUpperCase() + parts[1].slice(1).toLowerCase() + ' ' + parts[2] + ' ' + (this.field_time ? this.field_time : '00:00');
        this.$emit('update:modelValue', date.extractDate(d, 'DD MMM YYYY HH:mm'));
        this.timefieldref.resetValidation();
      }
    },
    close_date(){
      this.closing = true;
      this.visible = false
    }
  }
}
</script>

<style scoped>

</style>
