<template>
    <div v-if="!edit" v-html="data"></div>
    <q-editor v-else
        :disable="readonly"
        :model-value="data"
        @update:model-value="$emit('update:modelValue', $event)"
        :toolbar="[
          ['left', 'center', 'right', 'justify'],
          ['bold', 'italic', 'strike', 'underline'],
          ['unordered', 'ordered'],
          ['link']
        ]"
    />
</template>

<script>
import {onMounted, watch, ref} from "vue";

export default {
  props: ['label', 'modelValue', 'field', 'edit', 'readonly'],
  emits: ['update:modelValue'],
  name: "MultilineText",
  setup(props, {emit}) {
    const data = ref(null);
    onMounted(() => {
      data.value = props.modelValue;
      if (props.modelValue === undefined) {
        emit('update:modelValue', '');
      }
    });

    watch(() => props.modelValue, (newVal) => {
      if (newVal === undefined) {
        emit('update:modelValue', '');
      }
      data.value = newVal;
    }, {immediate: true});
    return {
      data
    }
  }
}
</script>

<style scoped>

</style>
